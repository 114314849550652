export class AdditionalCost {
    public id: number;
    public name: string;
    public cost: number;
    public type: number;
    public quantityRequested: number;
    public quantity: number;

    static fromDto(c: any): AdditionalCost {
        const additionalCost = new AdditionalCost();

        if (!c) {
            return null;
        }

        additionalCost.id = c.id;
        additionalCost.name = c.name;
        additionalCost.cost = c.cost;
        additionalCost.type = c.type;
        additionalCost.quantityRequested = c.quantityRequested ? c.quantityRequested : 0;
        additionalCost.quantity = c.quantity;

        return additionalCost;
    }
}

export class AdditionalCostsSaveOffer {
    public id: number;
    public quantity: number;

    static fromDto(c: any): AdditionalCostsSaveOffer {
      const additionalCostSaveOffer = new AdditionalCostsSaveOffer();

      if (!c) {
          return null;
      }

      additionalCostSaveOffer.id = c.id;
      additionalCostSaveOffer.quantity =  c.quantity;

      return additionalCostSaveOffer;
  }
}
