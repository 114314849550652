import {
  AfterViewInit,
  ViewChild,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewContainerRef,
  SimpleChange,
  EventEmitter,
  Output
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Option } from '../../shared/form-group-select/form-group-select.component';
import { Printer } from '../../shared/models/Printer';
import { PrintersService } from '../shared/printers.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, Type } from '@progress/kendo-angular-notification';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { PrinterType } from '../../shared/models/PrinterType';
import { PrinterManufacturer } from '../../shared/models/PrinterManufacturers';

@Component({
  selector: 'app-printer-form',
  templateUrl: './printer-form.component.html',
  styleUrls: ['./printer-form.component.css']
})
export class PrinterFormComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('notificationContainer', {read: ViewContainerRef})
  public notificationContainer: ViewContainerRef;

  @Input()
  public formType: 'create' | 'update' | 'read';

  @Input()
  public printer?: Printer;

  @Input()
  public typeOptions: Option[];

  public brandOptions: Option[] = [{value: 1, label: 'EPSON'}];

  public form: FormGroup;

  public errorsArray = [];

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private printersService: PrintersService,
    private formBuilder: FormBuilder,
    private dialogRef: DialogRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
    this.ngAfterViewInit();
  }

  ngOnInit() {
    const brand = new FormControl({value: this.brandOptions[0].value, disabled: true});
    if (this.formType === 'read') {
      const printerType: any = this.printer.printerType ?
        this.typeOptions.find((type) => `${type.value}` === `${this.printer.printerType.id}`) : null;
      this.form = this.formBuilder.group({
        company: new FormControl({value: this.printer.owner?.name, disabled: true}),
        brand: brand,
        type: new FormControl({value: printerType?.value, disabled: true}),
        name: new FormControl({value: this.printer.name, disabled: true}),
        macAddress: new FormControl({value: this.printer.identifier, disabled: true})
      });
    } else if (this.formType === 'update') {
      this.form = this.formBuilder.group({
        brand: brand,
        type: new FormControl({
          value: this.typeOptions.find((type) => `${type.value}` === `${this.printer.printerType.id}`),
          disabled: true
        }),
        name: [this.printer.name, Validators.required],
        macAddress: new FormControl(this.printer.identifier)
      });
    } else if (this.formType === 'create') {
      this.form = this.formBuilder.group({
        brand: brand,
        type: new FormControl(),
        name: ['', Validators.required],
        macAddress: ['', Validators.required]
      });
    }
  }

  ngAfterViewInit() {
    const htmlElement = document.getElementById('type') as HTMLElement;
    const selectElement = htmlElement.getElementsByTagName('select')[0];
    if (this.formType === 'update' || this.formType === 'read') {
      if (selectElement.options && this.printer.printerType) {
        for (let i = 0; i < selectElement.options.length; i++) {
          if (selectElement.options[i].value === this.printer.printerType.id.toString()) {
            selectElement.options[i].selected = true;
            break;
          }
        }
      }
    } else if (this.formType === 'create') {
      if (selectElement && selectElement.options?.length > 0) {
        const firstOption = selectElement.options[0];
        firstOption.selected = true;
        setTimeout(() => {
          selectElement.dispatchEvent(new Event('change'));
        }, 0);
      }
    }
  }

  public submit() {
    if (this.formType === 'create') {
      this.createPrinter();
    } else if (this.formType === 'update') {
      this.updatePrinter();
    } else if (this.formType === 'read') {
      this.addRentedPrinter();
    }
  }

  private showNotification(message: string, type: 'success' | 'error' | 'info' | 'warning') {
    this.notificationService.show({
      content: message,
      appendTo: this.notificationContainer,
      position: {horizontal: 'center', vertical: 'bottom'},
      type: {style: type, icon: false},
    });
  }

  private createPrinter() {
    if (this.form.valid) {
      const printerManufacturer = new PrinterManufacturer();
      printerManufacturer.id = 1; // EPSON
      const newPrinter = new Printer();
      newPrinter.printerType = new PrinterType();
      newPrinter.printerType.printerManufacturer = printerManufacturer;
      newPrinter.printerType.id = Number(this.form.get('type').value);
      newPrinter.name = this.form.get('name').value;
      newPrinter.identifier = this.form.get('macAddress').value;
      this.dialogRef.close();
      this.printersService.insert([newPrinter]).subscribe({
        next: () => {
          this.showNotification(this.translateService.instant('printers.message.added'), 'success');
          setTimeout(() => window.location.reload(), 1000);
        },
        error: () => {
          this.showNotification(this.translateService.instant('printers.message.addError'), 'error');
        },
      });
    } else {
      if (!this.form.controls['type'].valid) {
        this.errorsArray['type'] = this.translateService.instant('printers.errorMsg.type');
      }
      if (!this.form.controls['name'].valid) {
        this.errorsArray['name'] = this.translateService.instant('printers.errorMsg.name');
      }
      if (!this.form.controls['macAddress'].valid) {
        this.errorsArray['macAddress'] = this.translateService.instant('printers.errorMsg.macAddress');
      }
    }
  }

  private updatePrinter() {
    if (this.form.valid) {
      this.printer.name = this.form.get('name').value;
      this.printer.identifier = this.form.get('macAddress').value;
      this.dialogRef.close();
      this.printersService.update(this.printer).subscribe({
        next: () => {
          this.showNotification(this.translateService.instant('printers.message.updated'), 'success');
        },
        error: () => {
          this.showNotification(this.translateService.instant('printers.message.updatedError'), 'error');
        },
      });
    } else {
      if (!this.form.controls['name'].valid) {
        this.errorsArray['name'] = this.translateService.instant('printers.errorMsg.name');
      }
      if (!this.form.controls['macAddress'].valid) {
        this.errorsArray['macAddress'] = this.translateService.instant('printers.errorMsg.macAddress');
      }
    }
  }

  public addRentedPrinter() {
    this.dialogRef.close();
    this.printersService.insertRented([this.printer.rentingCode]).subscribe({
      next: () => {
        this.showNotification(this.translateService.instant('printers.message.rented'), 'success');
        setTimeout(() => window.location.reload(), 1000);
      },
      error: () => {
        this.showNotification(this.translateService.instant('printers.message.addError'), 'error');
      },
    });
  }
}
