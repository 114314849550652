import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Printer } from '../../shared/models/Printer';
import { PrintersService } from '../shared/printers.service';
import { Option } from '../../shared/form-group-select/form-group-select.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rent-printer-form',
  templateUrl: './rent-printer-form.component.html',
  styleUrls: ['./rent-printer-form.component.css']
})
export class RentPrinterFormComponent implements OnInit {

  public form: FormGroup;

  @Input()
  public typeOptions: Option[];

  @Input()
  public currentClientPrinters: Printer[];

  public printer?: Printer;

  public errorMsg?: string;

  constructor(
    private formBuilder: FormBuilder,
    private printersService: PrintersService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      search: ['7473F1287600', [Validators.required, Validators.minLength(1)]]
    });

    if (this.printer == null) {
      this.printer = new Printer();
      this.printer.id = 0;
      this.printer.name = '';
      this.printer.status = 0;
      this.printer.rentingCode = '';
      this.printer.events = null;
      this.printer.printerType = null;
      this.printer.owner = null;
    }
  }

  public submit() {
    if (this.form.valid) {
      this.errorMsg = null;
      const rentingCode = this.form.get('search').value;
      this.printersService.searchByRentingCode(rentingCode).subscribe({
        next: (res) => {
          if (res) {
            if (this.currentClientPrinters.findIndex((p) => p.id === res.id) !== -1) {
              this.errorMsg = this.translateService.instant('printers.errorMsg.rentingCode.ownPrinter');
              return;
            }
            this.printer = res;
          } else {
            this.errorMsg = this.translateService.instant('printers.errorMsg.rentingCode.notFound');
          }
        },
        error: (err) => {
          if (err.error.result === 'not_found') {
            this.errorMsg = this.translateService.instant('printers.errorMsg.rentingCode.notFound');
          } else {
            this.errorMsg = this.translateService.instant('printers.errorMsg.searchError');
          }
        },
      });
    } else {
      this.errorMsg = this.translateService.instant('printers.errorMsg.rentingCode.invalid');
    }
  }
}
