import { Event } from './Event';
import { SoftwareType } from './SoftwareType';

export class RequestedSoftwareType {
    public id: number;
    public quantity: number;
    public parentRequestId: number;
    public events: Event;
    public softwareType: SoftwareType;

    static fromDto(r: any): RequestedSoftwareType {
        const requestedSoftwareType = new RequestedSoftwareType();

        if (!r) {
            return null;
        }

        requestedSoftwareType.id = r.id;
        requestedSoftwareType.quantity = r.quantity;
        requestedSoftwareType.parentRequestId = r.parentRequestId;
        requestedSoftwareType.events = r.events;
        requestedSoftwareType.softwareType = r.softwareType;

        return requestedSoftwareType;
    }
}
