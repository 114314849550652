import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URL_BASE } from '../../core/constants';
import { HttpUtils } from '../../shared/http-utils';
import { map } from 'rxjs/operators';
import { Printer } from '../../shared/models/Printer';
import { PrinterManufacturer } from '../../shared/models/PrinterManufacturers';
import { PrinterType } from '../../shared/models/PrinterType';
import { PrinterRented } from 'src/app/shared/models/PrinterRented';

enum EndpointRoutes {
  INSERT = '/company/addPrinter',
  UPDATE = '/printer/update',
  DELETE = '/printer/delete',
  DELETE_RENTED = '/printer/deleteRented',
  GET_BY_RENTING_CODE = '/printer/rentingCode',
  INSERT_RENTED = '/company/addRentedPrinter',
  GET_MANUFACTURERS = 'printerManufacturer/get'
}

@Injectable({
  providedIn: 'root'
})
export class PrintersService {

  constructor(private _http: HttpClient) {
  }

  public searchByRentingCode(rentingCode: string): Observable<Printer> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + EndpointRoutes.GET_BY_RENTING_CODE, {
      headers,
      params: {
        rentingCode: rentingCode
      }
    }).pipe(map((res: any) => Printer.fromDto(res.printer)));
  }

  public delete(printerId: any): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + EndpointRoutes.DELETE, {
      headers,
      params: {
        id: printerId,
        force: 0,
      }
    }).pipe(map((res: any) => res));
  }

  public deleteRented(printerId: any): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + EndpointRoutes.DELETE_RENTED, {
      headers,
      params: {
        id: printerId,
        force: 0,
      }
    }).pipe(map((res: any) => res));
  }

  public insert(printers: Printer[]): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + EndpointRoutes.INSERT, {
      printers: printers
    }, {
      headers
    }).pipe(map((res: any) => res));
  }

  public update(printer: Printer): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + EndpointRoutes.UPDATE, {
      printer: printer
    }, {
      headers
    }).pipe(map((res: any) => res));
  }

  public insertRented(rentingCodes: string[]): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + EndpointRoutes.INSERT_RENTED, {
      rentingCodes: rentingCodes
    }, {
      headers
    }).pipe(map((res: any) => res));
  }

  public getPrinters(): Observable<Printer[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/printers/getByCompany', {headers: headers}).pipe(
      map((result: any[]) => result)
    );
  }

  public getPrintersRented(): Observable<PrinterRented[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/printers/getByCompany', {
      headers: headers,
      params: {
        rented: 'true'
      }
    }).pipe(
      map((result: any[]) => result.map(res => PrinterRented.fromDto(res)))
    );
  }

  public getAllPrinterTypes(): Observable<PrinterType[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/printerType/get', {headers: headers}).pipe(
      map((result: any[]) => result.map(res => PrinterType.fromDto(res)).filter(res => !res.isFiscalPrinter))
    );
  }

  public getPrinterManufacturers(): Observable<PrinterManufacturer[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + EndpointRoutes.GET_MANUFACTURERS, {headers: headers}).pipe(
      map((result: any[]) => result.map(res => PrinterManufacturer.fromDto(res)))
    );
  }

}
