import { Component, OnInit } from '@angular/core';
import { Event } from 'src/app/shared/models/Event';
import { EventService } from '../../shared/event.service';
import { RequestedCable } from 'src/app/shared/models/RequestedCable';
import { RequestedDevice } from 'src/app/shared/models/RequestedDevice';
import { DeviceUser } from 'src/app/shared/models/DeviceUser';
import { Printer } from 'src/app/shared/models/Printer';
import { ProductCategory } from 'src/app/shared/models/ProductCategory';
import { DeliveryAddress } from 'src/app/shared/models/DeliveryAddress';
import { AdditionalCost } from 'src/app/shared/models/AdditionalCost';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

class CableSizes {
  public length: number;
  public amount: number;
}

class DeviceUserPrinterCategory {
  public deviceUser: DeviceUser;
  public printerCategories: PrinterCategories[] = [];
}

class PrinterCategories {
  public printer: Printer;
  public productCategory: ProductCategory[] = [];
}

class PrintersCategoriesDeliveryPaper {
  public printerId: number;
  public printerName: string;
  public categoryName: string;
  public deviceName: string;
}

@Component({
  selector: 'app-delivery-paper',
  templateUrl: './delivery-paper.component.html',
  styleUrls: ['./delivery-paper.component.css']
})
export class DeliveryPaperComponent implements OnInit {

  public event: Event = new Event();
  public cablesAmount: CableSizes[] = [];
  public deviceUserPrinterCategory: DeviceUserPrinterCategory[] = [];
  public deviceUserPrinterCategorySorted: DeviceUserPrinterCategory[] = [];
  public printers: Printer[] = [];
  public printersCategoriesDeliveryPaper: PrintersCategoriesDeliveryPaper[] = [];

  public additionalCostsArray: AdditionalCost[];
  public currentLanguage = 'de';

  constructor(private _eventService: EventService, private _translate: TranslateService) { }

  ngOnInit() {
    this.currentLanguage = this._translate.currentLang;
    this._eventService.currentEventObject.subscribe(result => {
      if (result) {
        this.event = result;
        if (this.event.clientUser.company.village) {
          this.event.clientUser.company.village.name = this.getLocationName(this.event.clientUser.company.village.name, this.event.language);
        }

        this.event.printersCategories.forEach(printCat => {
          let existingDeviceUser = this.deviceUserPrinterCategory.filter(devUsr => devUsr.deviceUser.id === printCat.deviceUser.id)[0];
          if (!existingDeviceUser) {
            existingDeviceUser = new DeviceUserPrinterCategory();
            existingDeviceUser.deviceUser = printCat.deviceUser;
            this.deviceUserPrinterCategory.push(existingDeviceUser);
          }
          let existingDeviceUserPrinterCat = existingDeviceUser.printerCategories.filter(printer =>
            printer.printer.id === printCat.printer.id)[0];
          if (!existingDeviceUserPrinterCat) {
            existingDeviceUserPrinterCat = new PrinterCategories();
            existingDeviceUserPrinterCat.printer = printCat.printer;
            existingDeviceUser.printerCategories.push(existingDeviceUserPrinterCat);
          }
          existingDeviceUserPrinterCat.productCategory.push(printCat.productCategory);

        });
        console.log(this.deviceUserPrinterCategory);

        this._eventService.getPrintersCategoriesDeliveryPaperByEventId(this.event.id.toString()).subscribe(records => {
          if (records) {
            records.forEach(rec => {
            this.printersCategoriesDeliveryPaper.push(
              {
                printerId: rec.printerId,
                printerName: rec.printerName,
                categoryName: rec.categoryName,
                deviceName: rec.deviceName,
              });
            });
          }
        });
        console.log(this.printersCategoriesDeliveryPaper);

        const requestedDevices: RequestedDevice[] = [];
        this.event.requestedDevices.forEach(elem => {
          const existingDeviceType = requestedDevices.filter(deviceType => deviceType.deviceTypes.id === elem.deviceTypes.id)[0];
          if (!existingDeviceType) {
            requestedDevices.push(elem);
          } else {
            existingDeviceType.quantity += elem.quantity;
          }
        });
        this.event.requestedDevices = requestedDevices;
        this.calculateCableAmount(this.event.requestedCable);

        forkJoin([
          this._eventService.getAdditionalCosts(),
          this._eventService.getAdditionalCostsAssigned(this.event.id.toString())
        ]).subscribe(res1 => {
          const additionalCostsArray = res1[0];
          const additionalCostsAssignedArray = res1[1];
          additionalCostsAssignedArray.forEach(rec => {
            additionalCostsArray.filter(item => item.id === rec.id)[0].quantityRequested = rec.quantity;
          });
          this.additionalCostsArray = additionalCostsArray.filter(item => item.quantityRequested > 0 && item.name !== 'cancellation');
          console.log('this.additionalCostsArray', this.additionalCostsArray);
        });
      }
    });
    this._eventService.updateCurrentActiveSubPage('deliveryPaper');
  }

  getLocationName(locationName: string, language: string) {
    return (language === 'de' ? locationName.split('/').pop() : locationName.split('/').slice(0, -1).join('/'));
  }

  calculateCableAmount(requestedCable: RequestedCable[]) {
    requestedCable.forEach(cable => {
      this.cablesAmount.push({ length: cable.cables.cableType.length, amount: cable.quantity });
    });
  }

  getadditionalCostLabel(name) {
    console.log('name: ', this._translate.instant('offers.request.additionalCosts.'+ name));
    return this._translate.instant('offers.request.additionalCosts.' + name + '.title');
  }

}
