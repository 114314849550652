import { Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { EventComponent } from './event.component';
import { MyEventsComponent } from './my-events/my-events.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { DeviceUsersComponent } from './event-details/device-users/device-users.component';
import { GeneralDetailsComponent } from './event-details/general-details/general-details.component';
import { PriceListEventComponent } from './event-details/price-list-event/price-list-event.component';
// tslint:disable-next-line:max-line-length
import { AssignPrinterProductCategoryComponent } from './event-details/assign-printer-product-category/assign-printer-product-category.component';
import { DeliveryPaperComponent } from './event-details/delivery-paper/delivery-paper.component';
import { AdvertisingEventComponent } from './event-details/advertising-event/advertising-event.component';
import { StatisticsComponent } from './event-details/statistics/statistics.component';

export const EVENT_ROUTES: Routes = [
  {
    path: 'event', component: EventComponent, canActivate: [AuthGuard], children: [
      { path: 'my-events', component: MyEventsComponent },
      {
        path: 'details/:eventId', component: EventDetailsComponent, children: [
          { path: 'device-users', component: DeviceUsersComponent },
          { path: 'general', component: GeneralDetailsComponent },
          { path: 'price-list', component: PriceListEventComponent },
          { path: 'assign-printer-category', component: AssignPrinterProductCategoryComponent },
          { path: 'statistics', component: StatisticsComponent },
          { path: 'delivery-paper', component: DeliveryPaperComponent },
          { path: 'advertising', component: AdvertisingEventComponent },
          { path: '', redirectTo: 'general', pathMatch: 'full' }
        ]
      }
    ]
  }
];
