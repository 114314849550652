<kendo-grid
  kendoGridFocusable
  scrollable="none"
  [navigable]="true"
  [kendoGridBinding]="orderCategories"
  [kendoGridExpandDetailsBy]="expandDetailsBy"
  [(expandedDetailKeys)]="expandedDetailKeys"
  (cellClick)="onCellClick($event)"
>
  <kendo-grid-column field="name" title="{{'event.statistic.category' | translate}}" ></kendo-grid-column>
  <kendo-grid-column field="quantity" title="{{'event.statistic.quantity' | translate}}"></kendo-grid-column>
  <div *kendoGridDetailTemplate="let dataItem">
    <app-order-products [orderProducts]="dataItem.orderProduct"></app-order-products>
  </div>
</kendo-grid>
