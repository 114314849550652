import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Printer } from 'src/app/shared/models/Printer';
import { PrinterCategory, PriceListDeviceUser } from '../assign-printer-product-category.component';

@Component({
  selector: 'app-custom-switch-printer',
  templateUrl: './custom-switch-printer.component.html',
  styleUrls: ['./custom-switch-printer.component.css']
})
export class CustomSwitchPrinterComponent implements OnInit, OnChanges {

  @Input()
  printer: Printer;

  @Input()
  disabled = false;

  @Input()
  printerCategory: PrinterCategory;

  @Input()
  deviceUsersPriceList: PriceListDeviceUser;

  @Input()
  eventFinished = false;

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onSwitchChange = new EventEmitter<any>();

  public checked = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checked = false;
    const printer = changes.printer.currentValue;
    let printerCategory;
    if (!this.disabled && changes.printerCategory) {
      printerCategory = changes.printerCategory.currentValue;
      const isPresent = printerCategory.printerItems.filter(item => item.printer.id === printer.id)[0];
      if (isPresent) {
        this.checked = true;
      }
    }
  }

  onCheckBoxChange(deviceUserId: number, productCategoryId: number, printerId: number, deviceUserCategoryId: number) {
    this.onSwitchChange.emit({
      deviceUserId,
      productCategoryId,
      printerId,
      deviceUserCategoryId
    });
  }

}
