import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { PrintersService } from './shared/printers.service';
import { Printer } from '../shared/models/Printer';
import { PrinterRented } from '../shared/models/PrinterRented';
import { DialogService } from '@progress/kendo-angular-dialog';
import { PrinterFormComponent } from './printer-form/printer-form.component';
import { RentPrinterFormComponent } from './rent-printer-form/rent-printer-form.component';
import { Option } from '../shared/form-group-select/form-group-select.component';
import { TranslateService } from '@ngx-translate/core';
import { DeleteFormComponent } from './delete-form/delete-form.component';
import { PrintersFormComponent } from './printers-form/printers-form.component';
import { RentPrintersFormComponent } from './rent-printers-form/rent-printers-form.component';

@Component({
  selector: 'app-printers',
  templateUrl: './printers.component.html',
  styleUrls: ['./printers.component.css']
})
export class PrintersComponent implements OnInit {

  @ViewChild('notificationContainer', {read: ViewContainerRef})
  public notificationContainer: ViewContainerRef;

  @ViewChild('dialogContainer', {read: ViewContainerRef})
  public dialogContainer: ViewContainerRef;

  public printers: Printer[] = [];
  public printersRented: PrinterRented[] = [];
  public typeOptions: Option[] = [];

  constructor(
    private translateService: TranslateService,
    private printersService: PrintersService,
    private dialogService: DialogService,
  ) {
  }

  ngOnInit() {
    this.printersService.getAllPrinterTypes().subscribe({
      next: (res) => {
        this.typeOptions = res.map<Option>((t) => ({value: t.id, label: t.name})).reverse();
      },
      error: () => console.log('Unable to fetch printer types'),
    });
    this.printersService.getPrinters().subscribe({
      next: (res) => this.printers = res,
      error: () => console.log('Unable to fetch printers'),
    });
    this.printersService.getPrintersRented().subscribe({
      next: (res) => this.printersRented = res,
      error: () => console.log('Unable to fetch rented printers'),
    });
  }

  public showEditDialog(printer: Printer) {
    const dialogRef = this.dialogService.open({
      title: this.translateService.instant('printers.edit_printer'),
      content: PrinterFormComponent,
      appendTo: this.dialogContainer,
      width: 450,
      maxHeight: '70%'
    });
    const printerForm = dialogRef.content.instance as PrinterFormComponent;
    printerForm.formType = 'update';
    printerForm.printer = printer;
    printerForm.typeOptions = this.typeOptions;
  }

  public showCreateDialog() {
    const dialogRef = this.dialogService.open({
      title: this.translateService.instant('printers.add'),
      content: PrintersFormComponent,
      appendTo: this.dialogContainer,
      width: 450,
      maxHeight: '70%'
    });
    const printersForm = dialogRef.content.instance as PrintersFormComponent;
    printersForm.typeOptions = this.typeOptions;
  }

  public showRentalDialog() {
    const dialogRef = this.dialogService.open({
      title: this.translateService.instant('printers.add_rental'),
      content: RentPrintersFormComponent,
      appendTo: this.dialogContainer,
      width: 450,
      maxHeight: '85%'
    });
    const rentPrinterForm = dialogRef.content.instance as RentPrintersFormComponent;
    rentPrinterForm.currentClientPrinters = this.printers;
    rentPrinterForm.typeOptions = this.typeOptions;
  }

  public showDeleteDialog(printer: Printer | PrinterRented, rented = false) {
    const dialogRef = this.dialogService.open({
      title: this.translateService.instant('printers.delete_printer'),
      content: DeleteFormComponent,
      appendTo: this.dialogContainer,
      width: 450,
    });
    const deleteForm = dialogRef.content.instance as DeleteFormComponent;
    deleteForm.printer = printer;
    deleteForm.rented = rented;
  }

}
