import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { process, State } from '@progress/kendo-data-query';
import { TranslateService } from '@ngx-translate/core';
import { StatisticsService } from '../statistics.service';
import {
  DataBindingDirective,
  ExcelExportEvent,
  CellClickEvent,
  GridDataResult,
  MultipleSortSettings,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';
import { DeviceUser } from '../../../../shared/models/DeviceUser';
import { DeviceUserType } from '../../../../shared/models/DeviceUserType';
import { Workbook } from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';
import { ChartComponent } from '@progress/kendo-angular-charts';
import { drawDOM, exportPDF, Group } from '@progress/kendo-drawing';
import * as moment from 'moment';
import { table } from 'console';

@Component({
  selector: 'app-statistics-new',
  templateUrl: './statistics-new.component.html',
  styleUrls: ['./statistics-new.component.css']
})
export class StatisticsNewComponent implements OnInit {

  constructor(private _translate: TranslateService, private _statisticsKendoService: StatisticsService) {
    this.amountContent = this.amountContent.bind(this);
    this.quantityContent = this.quantityContent.bind(this);
    this.quantityAmountContent = this.quantityAmountContent.bind(this);
  }

  @Input() event: any;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  @ViewChild('salesCategoryChart') private salesCategoryChart: ChartComponent;
  @ViewChild('salesDeviceChart') private salesDeviceChart: ChartComponent;
  @ViewChild('qtyDeviceChart') private qtyDeviceChart: ChartComponent;
  @ViewChild('salesTablesChart') private salesTablesChart: ChartComponent;
  @ViewChild('qtyTablesChart') private qtyTablesChart: ChartComponent;
  @ViewChild('salesProductChart') private salesProductChart: ChartComponent;
  @ViewChild('ordersChart') private ordersChart: ChartComponent;
  @ViewChild('timeOrdersChart') private timeOrdersChart: ChartComponent;

  public gridData: GridDataResult;
  public statisticTurnoverData: any;

  public eventStartDate: Date;
  public eventEndDate: Date;
  public allPriceLists: any[];
  public selectedPriceList: any;
  public allDevices: DeviceUser[] = [];
  public allDeviceTypes: DeviceUserType[] = [];
  public selectedDevices: DeviceUser[] = [];
  public selectedDevicesEmpty = true;
  public selectedDeviceType: DeviceUserType;
  private allTablesArray = [];
  public allTables = [];
  public selectedTable: any;
  public hasWaiters = false;

  public hasFiscalPrinter: boolean;

  public selectionDateRange = {
    start: null,
    end: null
  };

  public selectionTimeRange = {
    start: null,
    end: null
  };

  public chartTimeRange = {
    start: null,
    end: null
  };

  public showChartChecked = true;
  public showStatistic = false;
  public showDeleteDataChecked = false;
  public dialogOpened = false;

  public pagingSettings = {
    pagerTypes: ['numeric', 'input'],
    type: 'input',
    // buttonCount: 5,
    info: true,
    previousNext: true,
    position: 'bottom',
    pageSize: 20,
    pageSizes: [
      10,
      20,
      50,
      100,
    ]
  };

  public sortingSettings: MultipleSortSettings = {
    allowUnsort: true,
  };

  public state: State = {
    skip: 0,
    take: 20,
  };

  public chartData: any = {};

  public expandedDetailKeys: number[] = [];

  public ordersData = [];
  public maxOrdersCount = 1;

  public timeOrdersData: any[];

  public tableStats = [];
  public tableNumbers = [];

  public ordersTable = [];

  public expandDetailsBy = (dataItem: any) => dataItem.id;

  ngOnInit(): void {
    // console.log(this.event);
    this.hasFiscalPrinter = this.event.requestedSoftwareTypes.find(rs => rs.softwareType.id === 5);
    this.eventStartDate = new Date(this.event.startDate);
    this.eventEndDate = new Date(this.event.endDate);
    this.selectionDateRange.start = new Date(this.event.startDate);
    this.selectionDateRange.end = new Date(this.event.endDate);
    this.selectionTimeRange.start = new Date(this.event.startDate);
    this.selectionTimeRange.end = new Date(this.event.endDate);
    this.selectionTimeRange.start.setHours(0, 1);
    this.selectionTimeRange.end.setHours(23, 59);
    this.chartTimeRange.start = new Date(this.event.startDate);
    this.chartTimeRange.start.setHours(0, 1);
    this.chartTimeRange.end = new Date(this.event.endDate);
    this.chartTimeRange.end.setHours(23, 59);
    this.hasWaiters = this.event.deviceUsers.filter(d => d.deviceUserType.id === 1).length > 0;
    this._setGridData();
  }

  public onCellClick(event: CellClickEvent): void {
    const findKeyState = this.expandedDetailKeys.findIndex((i) => i === event.dataItem.id);
    if (findKeyState === -1) {
      this.expandedDetailKeys.push(event.dataItem.id);
    } else {
      this.expandedDetailKeys.splice(findKeyState, 1);
    }
    this.expandedDetailKeys = [...this.expandedDetailKeys];
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.statisticTurnoverData.orders, this.state);
  }

  private _initPriceLists() {
    this.allPriceLists = this.event.priceLists;
    if (this.allPriceLists.length > 0) {
      if (this.allPriceLists.findIndex(pl => pl.id === -2) === -1) {
        const pushedItems = {};
        pushedItems['id'] = -2;
        pushedItems['name'] = this._translate.instant('event.statistic.all');
        pushedItems['createdAt'] = null;
        this.allPriceLists.unshift(pushedItems);
      }
      if (this.selectedPriceList?.id > 0) {
        this.selectedPriceList = this.allPriceLists.find(pl => pl.id === this.selectedPriceList.id);
      } else {
        this.selectedPriceList = this.allPriceLists[0];
      }
    }
  }

  private _initDeviceTypes() {
    this.allDeviceTypes = [];
      this.event.deviceUsers.map(d => d.deviceUserType).forEach(dt => {
        if (this.allDeviceTypes.findIndex(ad => ad.id === dt.id) === -1) {
          const deviceUserType = new DeviceUserType();
          deviceUserType.id = dt.id;
          deviceUserType.name = dt.name;
          this.allDeviceTypes.push(deviceUserType);
        }
    });
    this.statisticTurnoverData.orders.map(o => o.turnOver.device.deviceUserType).forEach(dt => {
      if (this.allDeviceTypes.findIndex(ad => ad.id === dt.id) === -1) {
        this.allDeviceTypes.push(dt);
      }
    });
    if (this.allDeviceTypes.length > 0) {
      const pushedItems = new DeviceUserType();
      pushedItems.id = -2;
      pushedItems.name = this._translate.instant('event.statistic.all');
      this.allDeviceTypes.unshift(pushedItems);
      if (this.selectedDeviceType?.id > 0) {
        this.selectedDeviceType = this.allDeviceTypes.find(dt => dt.id === this.selectedDeviceType.id);
        this.allDevices = this.allDevices.filter(d => d.deviceUserType.id === this.selectedDeviceType.id || d.deviceUserType.id === -2);
      } else {
        this.selectedDeviceType = this.allDeviceTypes[0];
      }
    }
  }

  private _initDevices() {
    this.allDevices = this.event.deviceUsers.map(d => {
      const device = new DeviceUser();
      device.id = d.id;
      device.name = d.name;
      const deviceUserType = new DeviceUserType();
      deviceUserType.id = d.deviceUserType.id;
      deviceUserType.name = d.deviceUserType.name;
      device.deviceUserType = deviceUserType;
      return device;
    });
    this.statisticTurnoverData.orders.map(o => o.turnOver.device).forEach(d => {
      if (this.allDevices.findIndex(ad => ad.id === d.id) === -1) {
        this.allDevices.push(d);
      }
    });
    if (this.allDevices.length > 0) {
      const pushedItems = new DeviceUser();
      pushedItems.id = -2;
      pushedItems.name = this._translate.instant('event.statistic.all');
      pushedItems.deviceUserType = new DeviceUserType();
      pushedItems.deviceUserType.id = -2;
      pushedItems.deviceUserType.name = this._translate.instant('event.statistic.all');
    }
  }

  public _initTablesArray(orders: any[]) {
    this.allTablesArray = [{id: -2, name: this._translate.instant('event.statistic.all'), devices: []}];
    this.allTablesArray[0].devices = this.allDevices.map(d => d.id);
    const tables = new Map();
    orders.forEach(o => {
      if (!tables.has(o.table)) {
        tables.set(o.table, {
          id: o.table,
          name: o.table,
          devices: [o.turnOver.deviceUserId]
        });
      } else {
        const existingTable = tables.get(o.table);
        const devices = existingTable.devices;
        if (!devices.find(dId => dId === o.turnOver.deviceUserId)) {
          devices.push(o.turnOver.deviceUserId);
        }
      }
    });
    this.allTablesArray.push(...Array.from(tables, ([_, v]) => (v)));
  }

  public _initTables() {
    if (this.selectedDevices.length <= 0) {
      this.allTables = this.allTablesArray;
    } else {
      this.allTables = [];
      this.selectedDevices.forEach(s => this.allTablesArray.filter(t => t.devices.findIndex(d => d === s.id) !== -1).forEach(a => this.allTables.push(a)));
      if (this.allTables.length === 1) {
        this.allTables.shift();
      }
    }
    if (this.selectedTable?.id > 0) {
      this.selectedTable = this.allTables.find(t => t.id === this.selectedTable.id);
    } else {
      this.selectedTable = this.allTables[0];
    }
  }

  private _setGridData() {
    this.showStatistic = false;
    const start = new Date(this.selectionDateRange.start);
    start.setHours(this.selectionTimeRange.start.getHours(), this.selectionTimeRange.start.getMinutes());
    const end = new Date(this.selectionDateRange.end);
    end.setHours(this.selectionTimeRange.end.getHours(), this.selectionTimeRange.end.getMinutes());

    this._initPriceLists();

    this._statisticsKendoService.getTurnoverData(
      this.event.id,
      this.selectedPriceList.id > 0 ? this.selectedPriceList.id : null,
      moment(start).format('DD-MM-YYYY HH:mm'),
      moment(end).format('DD-MM-YYYY HH:mm'),
      this.selectedDevices.length > 0 ? this.selectedDevices.map(s => s.id) : null,
      this.selectedDeviceType?.id > 0 ? this.selectedDeviceType.id : null,
      this.selectedTable?.id > 0 ? this.selectedTable.id : null,
    ).subscribe({
      next: (result) => {
        // console.log('result:', result);
        this.statisticTurnoverData = result;
        this._initDevices();
        this._initDeviceTypes();
        if (this.allTablesArray.length === 0) {
          this._initTablesArray(this.statisticTurnoverData.orders);
        }
        this._initTables();
        this._initOrdersStats();
        this._initTableStats();

        this.chartData = {
          categories: {
            names: result.sales.categories.map(c => c.name),
            amounts: result.sales.categories.map(c => c.amount),
          },
          products: {
            names: result.sales.products.map(p => p.name),
            amounts: result.sales.products.map(p => p.amount),
          }
        };

        this.gridData = process(this.statisticTurnoverData.orders, this.state);
        // this.excelExportData = this.excelExportData.bind(this);

        this.showStatistic = this.statisticTurnoverData?.orders?.length > 0;
        // console.log('statisticTurnoverData:', this.statisticTurnoverData);
      },
      error: () => console.log('Error on table rendering')
    });
  }

  public amountContent(e: any): string {
    return e.dataItem.name + ': ' + e.dataItem.amount + ' €';
  }

  public quantityContent(e: any): string {
    return e.dataItem.name + ': ' + e.dataItem.quantity;
  }

  public dateContent(e: any): string {
    return moment(new Date(e.text)).format('DD-MM-YYYY');
  }

  public quantityAmountContent(e: any): string {
    return e.dataItem.name + '\n' +
      this._translate.instant('event.statistic.salesLabel')  + ': ' + e.dataItem.amount.toFixed(2) + '€\n' +
      this._translate.instant('event.statistic.ordersLabel') + ': ' + e.dataItem.quantity;
  }

  private _initOrdersStats() {
    const orderMap = new Map<string, number>();

    this.statisticTurnoverData.orders.forEach(order => {
      const dateWithoutSeconds = new Date(order.createdAt);
      dateWithoutSeconds.setSeconds(0);

      const dateKey = dateWithoutSeconds.toISOString();

      if (orderMap.has(dateKey)) {
        orderMap.set(dateKey, orderMap.get(dateKey) + 1);
      } else {
        orderMap.set(dateKey, 1);
      }
    });

    this.ordersData = Array.from(orderMap.entries()).map(([dateString, count]) => ({
      date: new Date(dateString).toISOString(),
      count: count
    }));

    this.ordersData.sort((b, a) => new Date(b.date).getTime() - new Date(a.date).getTime());

    // console.log('orders data', this.ordersData);
    this.maxOrdersCount = Math.max(...this.ordersData.map(o => o.count)) + 1;
    this.buildFilteredTimeOrdersData();
    // console.log('timeOrdersData', this.timeOrdersData);
  }

  private _initTableStats() {
    const tableArr: any = [];
    const tableNumberArr: any = [];
    const ordersArr: any = [];

    this.statisticTurnoverData.sales.tables.forEach(table => {
      tableArr.push([table.amount, table.id]);
      tableNumberArr.push(table.id);
      ordersArr.push([table.quantity, table.id]);
    });

    tableArr.sort((a, b) => a[1].localeCompare(b[1], 'en', { numeric: true }));
    tableNumberArr.sort((a, b) => a.localeCompare(b, 'en', { numeric: true }));
    ordersArr.sort((a, b) => a[1].localeCompare(b[1], 'en', { numeric: true}));

    this.tableStats = tableArr;
    this.tableNumbers = tableNumberArr;
    this.ordersTable = ordersArr;
  }

  public buildFilteredTimeOrdersData() {
    const [startHour, startMinute] = this.chartTimeRange.start.toISOString().split('T')[1].split(':').map(Number);
    const [endHour, endMinute] = this.chartTimeRange.end.toISOString().split('T')[1].split(':').map(Number);

    const filteredTimeOrdersData =  this.ordersData.filter(order => {
      const date = new Date(order.date);
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();

      // Check if the time is within the specified range
      if (startHour <= endHour) {
        // Time range does not cross midnight
        return (hours > startHour || (hours === startHour && minutes >= startMinute)) &&
          (hours < endHour || (hours === endHour && minutes < endMinute));
      } else {
        // Time range crosses midnight
        return (hours > startHour || (hours === startHour && minutes >= startMinute)) ||
          (hours < endHour || (hours === endHour && minutes < endMinute));
      }
    });

    const data = filteredTimeOrdersData.reduce((acc, order) => {
      const date = new Date(order.date);
      const day = date.toISOString().split('T')[0];
      const time = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day].push({ count: order.count, time });
      return acc;
    }, {});

    this.timeOrdersData = Object.keys(data).map(day => ({day, orders: data[day]}));
  }

  public onSearchButtonClick() {
    if (this.showDeleteDataChecked) {
      this.dialogOpened = true;
    }
    this._setGridData();
  }

  public onDeleteButtonClick() {
    this.dialogOpened = true;
  }

  public onResetButtonClick() {
    this.showChartChecked = true;
    this.showStatistic = false;
    this.showDeleteDataChecked = false;
    this.selectedPriceList = this.allPriceLists.find(pl => pl.id === -2);
    this.selectedDeviceType = this.allDeviceTypes.find(dt => dt.id === -2);
    this.selectedDevices = [];
    this.ngOnInit();
  }

  public closeDialog(confirmDelete) {
    this.dialogOpened = false;
    if (confirmDelete) {
      this._statisticsKendoService.deleteTurnoverData(this.event.id).subscribe(result => {
        console.log('Deleted successfully!! Event id: ', this.event.id);
        this.onResetButtonClick();
        this._setGridData();
      });
    }
  }

  public onSelectDeviceType(event: any) {
    this.selectedDeviceType = this.allDeviceTypes.find(deviceType => deviceType.id === event.id);
    this._initDevices();
    if (this.selectedDeviceType.id === -2) {
      this._initTables();
    } else {
      this.allDevices = this.allDevices.filter(d => d.deviceUserType.id === this.selectedDeviceType.id || d.deviceUserType.id === -2);
      this.selectedDevices = this.selectedDevices.filter(d => d.deviceUserType.id === this.selectedDeviceType.id);
    }
  }

  public onSelectDevice(event: any) {
    if (event.length > 0) {
      this.selectedDevices = event;
      this.selectedDevicesEmpty = false;
    } else {
      this.selectedDevices = this.allDevices;
      this.selectedDevicesEmpty = true;
    }
    this._initTables();
  }

  public exportChartsToPDF(element) {
    const charts = [
      this.salesCategoryChart,
      this.salesDeviceChart,
      this.qtyDeviceChart,
      this.salesTablesChart,
      this.qtyTablesChart,
      this.salesProductChart,
      this.ordersChart,
      this.timeOrdersChart
    ];

    drawDOM(element, {
      paperSize: 'A4',
    })
      .then((group: Group) => {
        return exportPDF(group);
      })
      .then((dataUri) => {
        saveAs(dataUri, 'export.pdf');
      });
  }

  public onExcelExport(args: ExcelExportEvent) {
    args.preventDefault();

    const observables = [];
    const workbook = args.workbook;
    const rows = workbook.sheets[0].rows;

    const headerOptions = rows[0].cells[0];
    const data = this.gridData.data;

    for (let idx = 0; idx < data.length; idx++) {
      observables.push(data[idx]);
    }

    // zip.apply(Observable, observables).subscribe((result: GridDataResult[]) => {
      for (let idx = observables.length - 1; idx >= 0; idx--) {
        const orders = (<GridDataResult>observables[idx]).data;

        for (let orderIdx = orders.length - 1; orderIdx >= 0; orderIdx--) {
          const order = orders[orderIdx];
          rows.splice(idx + 2, 0, {
            cells: [
              {},
              {value: order.table},
              {value: order.createdAt},
              {value: order.turnOver.deviceUserName},
              {value: order.turnOver.deviceUserTypeName},
              {value: order.fiscalPrinted},
              {value: order.amount},
            ],
          });
        }

        rows.splice(idx + 2, 0, {
          cells: [
            {},
            Object.assign({}, headerOptions, {
              value: this._translate.instant('event.statistic.table'),
            }),
            Object.assign({}, headerOptions, {
              value: this._translate.instant('event.statistic.dateLabel'),
            }),
            Object.assign({}, headerOptions, {
              value: this._translate.instant('event.statistic.deviceUserName'),
            }),
            Object.assign({}, headerOptions, {
              value: this._translate.instant('event.statistic.deviceUserType'),
            }),
            Object.assign({}, headerOptions, {
              value: this._translate.instant('event.statistic.fiscalPrinted'),
            }),
            Object.assign({}, headerOptions, {
              value: this._translate.instant('event.statistic.totalAmount'),
            }),
          ],
        });
      }
    // });

    new Workbook(workbook).toDataURL().then((dataUrl: string) => {
      saveAs(dataUrl, 'statistic.xlsx');
    });
  }
}
