import { Printer } from './Printer';

export class PrinterRented {
  public id: number;
  public owner: string;
  public name: string;
  public type: string;
  public identifier: string;
  public status: boolean;

  static fromDto(p: any): PrinterRented {
    const printerRented = new PrinterRented();

    if (!p) {
      return null;
    }

    printerRented.id = p.id;
    printerRented.owner = p.owner?.name ?? '';
    const printer = Printer.fromDto(p.printer);
    printerRented.name = printer.name;
    printerRented.type = printer.printerType.name;
    printerRented.identifier = printer.identifier;
    printerRented.status = p.status;

    return printerRented;
  }
}
