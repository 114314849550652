import { NgModule } from '@angular/core';
import { PRINTERS_ROUTES } from './printers.routes';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { PrintersComponent } from './printers.component';
import { PrintersService } from './shared/printers.service';
import { PrinterFormComponent } from './printer-form/printer-form.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { RentPrinterFormComponent } from './rent-printer-form/rent-printer-form.component';
import { DeleteFormComponent } from './delete-form/delete-form.component';
import { PrintersFormComponent } from './printers-form/printers-form.component';
import { StepperModule } from '@progress/kendo-angular-layout';
import { RentPrintersFormComponent } from './rent-printers-form/rent-printers-form.component';

@NgModule({
  imports: [
    RouterModule.forChild(PRINTERS_ROUTES),
    SharedModule,
    CoreModule,
    NgSelectModule,
    DialogModule,
    StepperModule
  ],
  declarations: [
    PrintersComponent,
    PrinterFormComponent,
    PrintersFormComponent,
    RentPrinterFormComponent,
    DeleteFormComponent,
    RentPrintersFormComponent,
  ],
  providers: [
    PrintersService
  ]
})
export class PrintersModule {
}
