<form [formGroup]="form" (ngSubmit)="submit()">
  <app-form-group-input
    *ngIf="formType === 'read'"
    [formGroup]="form"
    disabled="true"
    id="company"
    name="company"
    label="{{'printers.company' | translate}}"
    formControlNameValue="company"
    ngDefaultControl>
  </app-form-group-input>
  <div class="row">
    <app-form-group-select
      class="col"
      [formGroup]="form"
      [options]="typeOptions"
      [error]="errorsArray['type']"
      disabled="{{formType !== 'create'}}"
      required="{{formType === 'create'}}"
      id="type"
      name="type"
      label="{{'printers.type' | translate}}{{formType === 'read' ? '' : ' *'}}"
      formControlNameValue="type"
      ngDefaultControl>
    </app-form-group-select>
    <app-form-group-select
      class="col"
      [formGroup]="form"
      [options]="brandOptions"
      disabled="true"
      required="true"
      id="brand"
      name="brand"
      label="{{'printers.brand' | translate}}{{formType === 'read' ? '' : ' *'}}"
      formControlNameValue="brand"
      ngDefaultControl>
    </app-form-group-select>
  </div>
  <app-form-group-input
    [formGroup]="form"
    [error]="errorsArray['name']"
    disabled="{{formType === 'read'}}"
    required="{{formType !== 'read'}}}"
    id="name"
    name="name"
    label="{{'printers.name' | translate}}{{formType === 'read' ? '' : ' *'}}"
    inputType="text"
    placeHolder="{{'printers.name' | translate}}"
    formControlNameValue="name"
    ngDefaultControl>
  </app-form-group-input>
  <app-form-group-input
    [formGroup]="form"
    [disabled]="formType !== 'create'"
    id="macAddress"
    name="{{'printers.macAddress' | translate}}"
    label="{{'printers.macAddress' | translate}}{{formType !== 'read' ? ' *' : ''}}"
    required="{{formType !== 'read'}}"
    [error]="errorsArray['macAddress']"
    inputType="text"
    formControlNameValue="macAddress"
    placeHolder="0B:3C:39:M3:2A:3B"
    ngDefaultControl>
  </app-form-group-input>
  <hr>
  <div *ngIf="formType==='update'" style="display: flex; justify-content: center">
    <button type="submit" [formGroup]="form" class="btn btn-block btn-fill btn-info">
      {{ formType === 'update' ? ('printers.save' | translate) : ('printers.add' | translate) }}
    </button>
  </div>
</form>
