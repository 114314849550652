<div class="wrapper">
  <div class="sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="main-panel">
    <app-top-navbar title="{{'sidebar.printers' | translate}}"></app-top-navbar>
    <div class="main-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="card-title">
                <h4 style="margin-top: 0;">{{ 'printers.myPrinters' | translate }}</h4>
              </div>
              <span class="table-description">{{ 'printers.myPrintersDescription' | translate }}</span>
              <br><br>
              <span class="table-description">{{ 'printers.identifier_desc' | translate }}</span>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                  <tr>
                    <th>{{ 'printers.name' | translate }}</th>
                    <th>{{ 'printers.type' | translate }}</th>
                    <th>{{ 'printers.macAddress' | translate }}</th>
                    <th>{{ 'printers.rentingCode' | translate }}</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngIf="printers.length > 0; else noPrinters">
                    <tr *ngFor="let printer of printers">
                      <td>{{ printer.name }}</td>
                      <td>{{ printer.printerType.name }}</td>
                      <td>{{ printer.identifier }}</td>
                      <td>{{ printer.rentingCode }}</td>
                      <td style="width: 60px">
                        <button
                          class="btn btn-sm btn-fill btn-warning mr-2"
                          (click)="showEditDialog(printer)">
                          {{ 'printers.edit' | translate }}
                        </button>
                        <button
                          class="btn btn-sm btn-fill btn-danger"
                          (click)="showDeleteDialog(printer)">
                          {{ 'printers.delete' | translate }}
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-template #noPrinters>
                    <tr class="mt-1">
                      <td colspan="6" class="text-center pt-4">
                        {{ 'printers.noPrinters' | translate }}
                      </td>
                    </tr>
                  </ng-template>
                  </tbody>
                </table>
              </div>
              <button class="btn btn-info btn-fill float-right" (click)="showCreateDialog()">
                {{ 'printers.add' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="card-title">
                <h4 style="margin-top: 0;">{{ 'printers.rentedPrinters' | translate }}</h4>
              </div>
              <span class="table-description">{{ 'printers.rentedPrintersDescription' | translate }}</span>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                  <tr>
                    <th>{{ 'printers.company' | translate}}</th>
                    <th>{{ 'printers.name' | translate }}</th>
                    <th>{{ 'printers.type' | translate }}</th>
                    <th>{{ 'printers.macAddress' | translate }}</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngIf="printersRented.length > 0; else noRentedPrinters">
                    <tr *ngFor="let printer of printersRented">
                      <td>{{ printer.owner !== '' ? printer.owner : 'RRSolutions' }}</td>
                      <td>{{ printer.name }}</td>
                      <td>{{ printer.type }}</td>
                      <td>{{ printer.identifier }}</td>
                      <td style="width: 105px">
                        <button
                          class="btn btn-sm btn-fill btn-danger"
                          (click)="showDeleteDialog(printer, true)">
                          {{ 'printers.delete' | translate }}
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-template #noRentedPrinters>
                    <tr>
                      <td colspan="5" class="text-center pt-4">
                        {{ 'printers.noPrintersRented' | translate }}
                      </td>
                    </tr>
                  </ng-template>
                  </tbody>
                </table>
              </div>
              <button class="btn btn-info btn-fill float-right" (click)="showRentalDialog()">
                {{ 'printers.add_rental' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div #notificationContainer></div>
  </div>
</div>
<div #dialogContainer></div>
