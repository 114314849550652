import { Event } from './Event';
import { PrinterType } from './PrinterType';
import { Company } from './Company';

export class Printer {
    public id: number;
    public name: string;
    public identifier: string;
    public status: number;
    public rentingCode: string;
    public events: Event [] = [];
    public printerType: PrinterType;
    public owner: Company;

    static fromDto(p: any): Printer {
        const printer = new Printer();

        if (!p) {
            return null;
        }

        printer.id = p.id;
        printer.name = p.name;
        printer.identifier = p.identifier;
        printer.status = p.status;
        printer.rentingCode = p.rentingCode;
        printer.events = p.events;
        printer.printerType = p.printerType;
        printer.owner = Company.fromDto(p.owner);

        return p;
    }
}
