import { ElementRef, OnInit, ViewChild, Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Client } from 'src/app/shared/models/Client';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.service';
import { NavigationService } from 'src/app/shared/navigation.service';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { TranslateService } from '@ngx-translate/core';
import { Company } from 'src/app/shared/models/Company';
import { Country } from 'src/app/shared/models/Country';
import { Router } from '@angular/router';
import { Village } from 'src/app/shared/models/Village';
import { AccountService } from 'src/app/account/shared/account.service';
import { Survey } from 'src/app/shared/models/Survey';
import { Language } from '../language';
import { LOCAL_STORAGE } from '../constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ValidationUtils } from 'src/app/shared/validation.utils';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @ViewChild('inputLocation') inputLocation?: ElementRef;

  public languages = Language.getAvailableLanguages();

  public registerForm: FormGroup;
  public companyRegisterForm: FormGroup;
  public errorArray = new Array();
  public companyErrorArray = new Array();
  public currentDate = new Date();
  public languageOptions: Option[] = [
  { value: 'de', label: 'register.german' },
  { value: 'it', label: 'register.italian' },
  { value: 'en', label: 'register.english' }
];
  public titleOptions: Option[] = [
    { value: 'Herr', label: 'register.herr' },
    { value: 'Frau', label: 'register.frau' }
  ];

  public surveys: Survey[] = [];

  public currentLanguage: any;
  public chosenVillage: Village;
  public chosenCountry: Country;
  public missingFirstNameErrorMsg = '';
  public missingLastNameErrorMsg = '';
  public missingEmailErrorMsg = '';
  public missingPasswordErrorMsg = '';
  public retypePasswordErrorMsg = '';
  public missingPhoneNumberErrorMsg = '';
  public missingOrganizationErrorMsg = '';
  public missingVillageErrorMsg = '';
  public missingSurveyErrorMsg = '';
  public missingTermsErrorMsg = '';

  public locationGroupClass = 'col';
  public surveyGroupClass = 'row';

  public inputActive = false;

  constructor(private _toastr: ToastrService, private _formBuilder: FormBuilder, private _authService: AuthService,
    private _navigation: NavigationService, private _router: Router, private _translate: TranslateService,
    private _accountService: AccountService, private _jwtHelper: JwtHelperService
    ) {
    this.currentLanguage = this._translate.currentLang;
    console.log(this.currentLanguage);
    this.registerForm = this._formBuilder.group({
      title: ['Herr', <any>Validators.required],
      firstName: ['', <any>Validators.required],
      lastName: ['', <any>Validators.required],
      firstEmail: ['', [<any>Validators.required, <any>Validators.email]],
      password: ['', [<any>Validators.required, <any>Validators.minLength(6)]],
      retypePassword: ['', <any>Validators.required],
      firstPhone: ['', <any>Validators.required],
      newsletter: [false],
      termsConditionsCheck: [false],
      termsConditionsCheckPoint4: [true],
      termsConditionsCheckPoint6: [true],
      termsConditionsCheckPoint9: [true],
      language: [this.currentLanguage, [<any>Validators.required]],
      survey: [0],
      surveyDescription: ['']
    }, {
      validators: [
        this.checkTermsAndConditions('termsConditionsCheck'),
        this.checkTermsAndConditions('termsConditionsCheckPoint4'),
        this.checkTermsAndConditions('termsConditionsCheckPoint6'),
        this.checkTermsAndConditions('termsConditionsCheckPoint9'),
        ValidationUtils.checkIfMatchingPasswords('password', 'retypePassword')
      ],
    });
    this.companyRegisterForm = this._formBuilder.group({
      name: ['', [<any>Validators.required, <any>Validators.minLength(2)]],
    });
  }

  ngOnInit() {
    if (this._authService.isAuthenticated()) {
      return this._navigation.home().go();
    }
    this.registerForm.get('language').setValue(localStorage.getItem('locale'));
    this._accountService.getSurveyOptions().subscribe(results => {
      this.surveys = results;
      this.surveys.forEach(item => {
        switch (this.currentLanguage) {
          case 'it': item.name = item.nameIt;
            break;
          case 'de': item.name = item.nameDe;
            break;
          default: item.name = item.nameEn;
        }
      });
    });
  }

  onRegistration() {

    if (this.registerForm.controls['firstName'].value === '') {
      this.missingFirstNameErrorMsg = this._translate.instant('account.errorMsg.missingFirstName');
    } else {
      this.missingFirstNameErrorMsg = null;
    }

    if (this.registerForm.controls['lastName'].value === '') {
      this.missingLastNameErrorMsg = this._translate.instant('account.errorMsg.missingLastName');
    } else {
      this.missingLastNameErrorMsg = null;
    }

    if (this.registerForm.controls['firstEmail'].value === '') {
      this.missingEmailErrorMsg = this._translate.instant('account.errorMsg.missingEmail');
    } else {
      this.missingEmailErrorMsg = null;
    }

    if (this.registerForm.controls['password'].value === '') {
      this.missingPasswordErrorMsg = this._translate.instant('account.errorMsg.missingPassword');
    } else {
      if (this.registerForm.controls['password'].value.length < 6) {
        this.missingPasswordErrorMsg = this._translate.instant('account.errorMsg.passwordShortError');
      } else {
        this.missingPasswordErrorMsg = null;
      }
    }

    if (this.registerForm.controls['retypePassword'].value === '') {
      this.retypePasswordErrorMsg = this._translate.instant('account.errorMsg.missingRepeatPassword');
    } else {
      if (this.registerForm.controls['password'].value !== this.registerForm.controls['retypePassword'].value) {
        this.retypePasswordErrorMsg = this._translate.instant('account.errorMsg.matchPasswordError');
      } else {
        this.retypePasswordErrorMsg = null;
      }
    }

    if (this.registerForm.controls['firstPhone'].value === '') {
      this.missingPhoneNumberErrorMsg = this._translate.instant('account.errorMsg.missingPhoneNumber');
    } else {
      this.missingPhoneNumberErrorMsg = null;
    }

    if (this.companyRegisterForm.controls['name'].value === '') {
      this.missingOrganizationErrorMsg = this._translate.instant('account.errorMsg.missingOrganization');
    } else {
      this.missingOrganizationErrorMsg = null;
    }

    if (!this.chosenCountry) {
      this.locationGroupClass = 'col border-red';
      this.missingVillageErrorMsg = this._translate.instant('account.errorMsg.missingCountry');
    } else if (this.chosenCountry.id === 1) {
      if (!this.chosenVillage) {
        this.locationGroupClass = 'col border-red';
        this.missingVillageErrorMsg = this._translate.instant('account.errorMsg.missingVillage');
      } else {
        this.missingVillageErrorMsg = null;
        this.locationGroupClass = 'col';
      }
    }  else {
      this.missingVillageErrorMsg = null;
      this.locationGroupClass = 'col';
    }

    if (this.registerForm.controls['survey'].value === 0) {
      this.surveyGroupClass = 'row border-red-two';
      this.missingSurveyErrorMsg = this._translate.instant('account.errorMsg.missingSurvey');
    } else {
      this.missingSurveyErrorMsg = null;
      this.surveyGroupClass = 'row';
    }

    if (!this.registerForm.controls['termsConditionsCheck'].valid) {
      this.missingTermsErrorMsg = this._translate.instant('account.errorMsg.missingTerms');
    } else {
      this.missingTermsErrorMsg = null;
    }

    if (this.registerForm.valid && this.companyRegisterForm.valid) {
      const company = Company.fromDto(this.companyRegisterForm.value);
      const client = Client.fromDto(this.registerForm.value);
      company.status = 1;
      client.company = company;
      client.username = client.firstEmail;
      client.company.country = this.chosenCountry;
      if (this.chosenVillage) {
        client.villageId = this.chosenVillage.id;
        client.company.village = this.chosenVillage;
      }
      client.answer.surveyId = this.registerForm.controls['survey'].value;
      if (this.registerForm.controls['survey'].value === 7) {
        client.answer.description = this.registerForm.controls['surveyDescription'].value;
      } else {
        client.answer.description = '';
      }
      this._authService.registerNewClientUser(client).subscribe(result => {

        this.registerForm.reset({ language: client.language });
        this._translate.use(client.language);
        localStorage.setItem('locale', client.language);
        this._router.navigate(['/login'], { queryParams: { action: 'register' } });
        // this._navigation.login({ queryParams: { action: 'register' } }).go();
      }, error => {
        if (error.status === 600) {
          this.errorArray['firstEmail'] = 'register.errorMsg.emailExist';
        }
      });
    } else {
      this._toastr.error(this._translate.instant('account.errorMsg.invalidForm'));
    }
  }

  checkTermsAndConditions(termsConditionsCheckKey: string) {
    return (group: FormGroup) => {
      const termsAndConditionsCheck = group.controls[termsConditionsCheckKey];
      if (!termsAndConditionsCheck.value) {
        return termsAndConditionsCheck.setErrors({ notTerms: true });
      } else {
        return termsAndConditionsCheck.setErrors(null);
      }
    };
  }

  changeLanguage(language: Language) {
    this.currentLanguage = language;
    this._translate.use(this.currentLanguage.locale);
    localStorage.setItem('locale', this.currentLanguage.locale);
  }

  onLanguageChange(value: any) {
    switch (value) {
      case "de": this.changeLanguage(this.languages[1]);
        break;
      case "it": this.changeLanguage(this.languages[2]);
        break;
      default: this.changeLanguage(this.languages[0]);
    }
  }

  onCheckBoxClick(id: any) {
    if (id === 7) {
      this.inputActive = true;
    } else {
      this.inputActive = false;
      this.registerForm.get('surveyDescription').setValue('');
    }
  }
}
