<div class="wrapper">
    <div class="sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="main-panel">
        <app-top-navbar title="{{'event.myEvents.myEvents' | translate}}"></app-top-navbar>
        <div class="main-content container-fluid">
            <div class="row">
                <ng-container *ngFor="let year of years">
                    <div class="col-12">
                        <h2>{{year}}</h2>
                        <hr />
                        <div class="card-deck row">
                            <ng-container *ngFor="let event of events[year]">
                                <div class="col-lg-4 col-md-12 mb-3">
                                    <div class="card border">
                                        <div class="card-body">
                                            <h3 class="card-title border-bottom mt-0 pb-2">
                                                <b>{{event.eventName}}</b>
                                            </h3>
                                            <div class="row text-center">
                                                <div class="col">
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <span
                                                                class="m-0 text-muted">{{'event.myEvents.startDate' | translate}}</span>
                                                            <h5 class="m-0" style="color: #000000!important;">
                                                                <strong>{{'event.myEvents.' + (event.startDate | date:'E').toLowerCase() | translate}} {{event.startDate | date:'dd-MM-yyyy'}}</strong>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <span
                                                                class="m-0 text-muted">{{'event.myEvents.endDate' | translate}}</span>
                                                            <h5 class="m-0" style="color: #000000!important;">
                                                                <strong>{{'event.myEvents.' + (event.endDate | date:'E').toLowerCase() | translate}} {{event.endDate | date:'dd-MM-yyyy'}}</strong>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row text-center mt-3">
                                                <div class="col">
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <span
                                                                class="m-0 text-muted">{{'event.myEvents.pickUpDate' | translate}}</span>
                                                            <h5 class="m-0" style="color: #000000!important;">
                                                                <strong>{{'event.myEvents.' + (event.pickUpDate | date:'E').toLowerCase() | translate}} {{event.pickUpDate | date:'dd-MM-yyyy'}}</strong>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <span
                                                                class="m-0 text-muted">{{'event.myEvents.returnDate' | translate}}</span>
                                                            <h5 class="m-0" style="color: #000000!important;">
                                                                <strong>{{'event.myEvents.' + (event.returnDate | date:'E').toLowerCase() | translate}} {{event.returnDate | date:'dd-MM-yyyy'}}</strong>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="row">
                                                <div class="col-6">
                                                    <ul class="list-unstyled m-0">
                                                        <li class="media">
                                                            <div class="media-body">
                                                                <span
                                                                    class="m-0 text-muted">{{'event.myEvents.status' | translate}}</span>
                                                                <h5 class="m-0">
                                                                    <strong
                                                                        [innerHTML]="event.status | eventStatus:event.startDate:event.endDate "></strong>
                                                                </h5>
                                                            </div>
                                                        </li>
                                                        <li class="media mt-3" *ngIf="event.eventType.toString() !== 'offer'">
                                                            <div class="media-body">
                                                                <span class="m-0 text-muted">
                                                                    {{'event.myEvents.advertisingStatus' | translate}}</span>
                                                                <h5 class="m-0">
                                                                    <ng-container
                                                                        *ngIf="event.hasOnlineEvent; else offlineTemplate">
                                                                        <strong style="color:#1DC7EA">{{'event.myEvents.advOnline' | translate}}</strong>
                                                                    </ng-container>
                                                                    <ng-template #offlineTemplate>
                                                                        <strong style="color:#000000">{{'event.myEvents.advOffline' | translate}}</strong>
                                                                    </ng-template>
                                                                </h5>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="col-6">
                                                    <ul class="list-unstyled text-right m-0">
                                                        <li class="media">
                                                            <div class="media-body">
                                                                <ng-container>
                                                                    <button type="button" class="btn btn-sm btn-info"
                                                                        (click)="downloadOfferPdf(event.offerLongId, event.offerVersionLetter, event.createdAt)"
                                                                        style="margin-top: 5px; width:100%;"><i class="fa fa-file-pdf"></i>{{'event.myEvents.offerPdf' | translate}}</button>
                                                                </ng-container>
                                                                <ng-container>
                                                                  <ng-container *ngIf="!event.isEventFinish; else eventFinishTemplate">
                                                                    <a href="event/details/{{event.id}}/advertising"
                                                                    class="btn btn-sm btn-info"
                                                                     [ngStyle]="{'pointer-events': event.status === 1 ? 'none' : 'auto', 'opacity': event.status === 1 ? '0.5' : '1'}"
                                                                    style="margin-top: 5px; width:100%;">
                                                                      <i class="fa fa-arrow-right"></i>{{'event.myEvents.view' | translate}}
                                                                    </a>
                                                                  </ng-container>
                                                                  <ng-template #eventFinishTemplate>
                                                                    <a href="event/details/{{event.id}}/statistics"
                                                                    class="btn btn-sm btn-info"
                                                                    style="margin-top: 5px; width:100%;"><i class="fa fa-arrow-right"></i>{{'event.myEvents.view' | translate}}</a>
                                                                  </ng-template>
                                                                </ng-container>
                                                            </div>
                                                        </li>
                                                        <!-- <li class="media mt-3" *ngIf="event.eventType !== 'offer'">
                                                            <div class="media-body">
                                                                <a href="event/details/{{event.id}}/advertising"
                                                                    class="btn btn-sm btn-warning"
                                                                    style="margin-top: 5px;">{{(!event.hasOnlineEvent ? 'event.myEvents.advertise' : 'event.myEvents.editAdvertise') | translate}}</a>
                                                            </div>
                                                        </li> -->
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <hr />
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
