<div class="row" *ngIf="countriesAndVillageLoaded">
  <div class="col">
    <div class="form-group">
      <label class="control-label col-form-label-sm">{{ 'offers.request.country' | translate }} *</label>
      <ng-select class="custom"
        [items]="countryOptions" [(ngModel)]="chosenCountry"
        (change)="onSelectChange('country')">
      </ng-select>
    </div>
  </div>
  <ng-container *ngIf="chosenCountry && chosenCountry.value === 1">
    <div class="col">
      <div class="form-group">
        <label class="control-label col-form-label-sm">{{ 'offers.request.region' | translate }} *</label>
        <ng-select class="custom"
           [items]="regionOptions" [(ngModel)]="chosenRegion" [disabled]="!chosenCountry"
           (change)="onSelectChange('region')">
        </ng-select>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label class="control-label col-form-label-sm">{{ 'offers.request.province' | translate }}*</label>
        <ng-select class="custom"
           [items]="provinceOptions" [(ngModel)]="chosenProvince" [disabled]="!chosenRegion"
           (change)="onSelectChange('province')">
        </ng-select>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label class="control-label col-form-label-sm">{{ 'offers.request.village' | translate }}*</label>
        <ng-select class="custom"
           [items]="villageOptions" [(ngModel)]="chosenVillage" [disabled]="!chosenProvince"
           (change)="onSelectChange('village')">
        </ng-select>
      </div>
    </div>
  </ng-container>
</div>
