import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Printer } from '../../shared/models/Printer';
import { PrintersService } from '../shared/printers.service';
import { NotificationService } from '@progress/kendo-angular-notification';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { TranslateService } from '@ngx-translate/core';
import { PrinterRented } from '../../shared/models/PrinterRented';

@Component({
  selector: 'app-delete-form',
  templateUrl: './delete-form.component.html',
  styleUrls: ['./delete-form.component.css']
})
export class DeleteFormComponent implements OnInit {
  @ViewChild('notificationContainer', {read: ViewContainerRef})
  public notificationContainer: ViewContainerRef;

  @Input()
  public printer: Printer | PrinterRented;

  @Input()
  public rented: Boolean = false;

  constructor(
    private translateService: TranslateService,
    private printersService: PrintersService,
    private notificationService: NotificationService,
    private dialogRef: DialogRef) {
  }

  ngOnInit(): void {
  }

  public submit() {
    this.printer.status = 0;
    this.dialogRef.close();
    const promise = this.rented ? this.printersService.deleteRented(this.printer.id) : this.printersService.delete(this.printer.id);

    promise.subscribe({
      next: () => {
        this.showNotification(this.translateService.instant('printers.message.deleted'), 'success');
        setTimeout(() => window.location.reload(), 1000);
      },
      error: () => {
        this.showNotification(this.translateService.instant('printers.message.deleteError'), 'error');
      },
    });
  }

  private showNotification(message: string, type: 'success' | 'error' | 'info' | 'warning') {
    this.notificationService.show({
      content: message,
      appendTo: this.notificationContainer,
      position: {horizontal: 'center', vertical: 'bottom'},
      type: {style: type, icon: false},
    });
  }
}
