<ng-container *ngIf="disableDeviceMenuItem; else overviewTemplate">
  <div class="row">
    <div class="col">
      <div class="jumbotron" style="padding-top: 0px; padding-bottom: 0px;">
        <h1 class="display-4">{{'event.details.usersAndAccessDatas' | translate}}</h1>
        <p class="lead">
          {{'event.details.missingHardwareDesc' | translate}}
        </p>
        <hr class="my-4">
      </div>
    </div>
  </div>
</ng-container>
<ng-template #overviewTemplate>
  <div class="row">
    <div class="col">
      <div class="jumbotron" style="padding-top: 0px; padding-bottom: 0px;">
        <h1 class="display-4">{{'event.details.usersAndAccessDatas' | translate}}</h1>
        <p class="lead">{{'event.details.usersAndAccessDatasDesc' | translate}}</p>
        <hr class="my-4">
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <div class="card" *ngIf="event.deviceUsers.length > 0">
        <div class="card-header" style="position: relative;">
          {{'event.details.deviceUsers' | translate}}
          <div class="scroll-info">
            &rArr;&nbsp;&nbsp;Scroll
          </div>
        </div>
        <div class="card-body" style="width: 100%; overflow-y: scroll;">
          <div class="table-responsive" style="width:100%;">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>{{'event.details.rule' | translate}}</th>
                  <th>{{'event.details.username' | translate}}</th>
                  <th>{{'event.details.accessCode' | translate}}</th>
                  <th>PIN</th>
                  <th #showPaymentsAnchor (click)="togglePopup(showPaymentsAnchor, showPaymentsPopup)" style="cursor: pointer" class="ignore-click-listener">
                    <span style="text-decoration: underline" class="ignore-click-listener">{{ 'event.details.showPayments' | translate }}</span>
                    <i class="fa fa-info ignore-click-listener"></i>
                  </th>
                  <th #showSalesAnchor (click)="togglePopup(showSalesAnchor, showSalesPopup)" style="cursor: pointer" class="ignore-click-listener">
                    <span style="text-decoration: underline" class="ignore-click-listener">{{ 'event.details.showSales' | translate }}</span>
                    <i class="fa fa-info ignore-click-listener"></i>
                  </th>
                  <th #doublePrintAnchor (click)="togglePopup(doublePrintAnchor, doublePrintPopup)" style="cursor: pointer" class="ignore-click-listener">
                    <span style="text-decoration: underline" class="ignore-click-listener">{{ 'event.details.enableDoublePrint' | translate }}</span>
                    <i class="fa fa-info ignore-click-listener"></i>
                  </th>
                  <th #paidWithoutPrintAnchor (click)="togglePopup(paidWithoutPrintAnchor, paidWithoutPrintPopup)" style="cursor: pointer" class="ignore-click-listener">
                    <span style="text-decoration: underline" class="ignore-click-listener">{{ 'event.details.paidWithoutPrint' | translate }}</span>
                    <i class="fa fa-info ignore-click-listener"></i>
                  </th>
                  <th #enableSumUpAnchor (click)="togglePopup(enableSumUpAnchor, enableSumUpPopup)" style="cursor: pointer" class="ignore-click-listener" *ngIf="event.hasSumUp">
                    <span style="text-decoration: underline" class="ignore-click-listener">{{ 'event.details.enableSumup' | translate }}</span>
                    <i class="fa fa-info ignore-click-listener"></i>
                  </th>
                  <th #enableVivaWalletAnchor (click)="togglePopup(enableVivaWalletAnchor, enableVivaWalletPopup)" style="cursor: pointer" class="ignore-click-listener" *ngIf="event.hasVivaWallet">
                    <span style="text-decoration: underline" class="ignore-click-listener">{{ 'event.details.enableVivaWallet' | translate }}</span>
                    <i class="fa fa-info ignore-click-listener"></i>
                  </th>
                  <th *ngIf="fiscalPrinterLicenses.qtySoftware > 0 || fiscalPrinterLicenses.qtyHardware > 0"
                      #enableFiscalPrinterAnchor (click)="togglePopup(enableFiscalPrinterAnchor, enableFiscalPrinterPopup)" style="cursor: pointer" class="ignore-click-listener"
                  >
                    <span style="text-decoration: underline" class="ignore-click-listener">{{ 'event.details.enableFiscalPrinter' | translate }}</span>
                    <i class="fa fa-info ignore-click-listener"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let deviceUser of event.deviceUsers">
                  <td>{{'event.details.' + deviceUser.deviceUserType.name | translate}}</td>
                  <td><input
                    class="form-control form-control-sm"
                    type="text"
                    [attr.id]="'input-username-' + deviceUser.id"
                    value="{{deviceUser.name}}"
                    placeholder="{{deviceUser.name}}"/>
                  </td>
                  <td>{{deviceUser.pinCode}}</td>
                  <td>{{deviceUser.lvlOnePin}}</td>
                  <td><input type="checkbox" [(ngModel)]="deviceUser.enablePaymentScreen" [attr.id]="'checkbox-payment-' + deviceUser.id"></td>
                  <td><input type="checkbox" [(ngModel)]="deviceUser.showSales" [attr.id]="'checkbox-sales-' + deviceUser.id"></td>
                  <td><input type="checkbox" [(ngModel)]="deviceUser.enableDoublePrint" [attr.id]="'checkbox-double-print-' + deviceUser.id" [disabled]="eventWithoutPrinter"></td>
                  <td><input type="checkbox" [(ngModel)]="deviceUser.paidWithoutPrint" [attr.id]="'checkbox-paidWithoutPrint-' + deviceUser.id" [disabled]="eventWithoutPrinter"></td>
                  <td *ngIf="event.hasSumUp">
                    <input *ngIf="deviceUser.deviceUserType.id === 1 || deviceUser.deviceUserType.id === 2; else disabledCheckbox"
                      type="checkbox" [(ngModel)]="deviceUser.enableSumUp" [attr.id]="'checkbox-sumup-' + deviceUser.id">
                  </td>
                  <td *ngIf="event.hasVivaWallet">
                    <input *ngIf="deviceUser.deviceUserType.id === 1 || deviceUser.deviceUserType.id === 2; else disabledCheckbox"
                      type="checkbox" [(ngModel)]="deviceUser.enableVivaWallet" [attr.id]="'checkbox-vivawallet-' + deviceUser.id">
                  </td>
                  <td *ngIf="fiscalPrinterLicenses.qtySoftware > 0 || fiscalPrinterLicenses.qtyHardware > 0">
                    <input *ngIf="deviceUser.deviceUserType.id === 1 || deviceUser.deviceUserType.id === 2; else disabledCheckbox"
                      type="checkbox" [(ngModel)]="deviceUser.hasFiscalPrinter" [attr.id]="'checkbox-hasFiscalPrinter-' + deviceUser.id">
                  </td>
                  <ng-template #disabledCheckbox><input type="checkbox" [disabled]="true"/></ng-template>
                </tr>
              </tbody>
            </table>
            <ng-template #showPaymentsPopup>
              <div class="device-popup ignore-click-listener">{{'event.details.showPayments_description' | translate}}</div>
            </ng-template>
            <ng-template #showSalesPopup>
              <div class="device-popup ignore-click-listener">{{'event.details.showSales_description' | translate}}</div>
            </ng-template>
            <ng-template #doublePrintPopup>
              <div class="device-popup ignore-click-listener">{{'event.details.enableDoublePrint_description' | translate}}</div>
            </ng-template>
            <ng-template #paidWithoutPrintPopup>
              <div class="device-popup ignore-click-listener">{{'event.details.paidWithoutPrint_description' | translate}}</div>
            </ng-template>
            <ng-template #enableSumUpPopup>
              <div class="device-popup ignore-click-listener">{{ sumUpDescription }}</div>
            </ng-template>
            <ng-template #enableVivaWalletPopup>
              <div class="device-popup ignore-click-listener">{{ vivaWalletDescription }}</div>
            </ng-template>
            <ng-template #enableFiscalPrinterPopup>
              <div class="device-popup ignore-click-listener">{{ fiscalPrinterDescription }}</div>
            </ng-template>
            <hr/>
            <div style="display: flex; gap: 50px;">
              <div *ngIf="event.hasVivaWallet && hasVivaWalletUsers()" style="width: 50%;">
                <h6 class="card-title">
                  {{'event.details.configureVivaWallet' | translate}}
                </h6>
                <div class="table-responsive">
                  <table class="table table-sm">
                    <thead>
                      <tr>
                        <th>{{'event.details.device' | translate}}</th>
                        <th>Client ID *</th>
                        <th>Client Secret *</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let deviceUser of event.deviceUsers">
                        <ng-container *ngIf="deviceUser.enableVivaWallet">
                          <td>{{deviceUser.name}}</td>
                          <td>
                              <input class="form-control form-control-sm" type="text" [attr.id]="'input-isv-client-id-' + deviceUser.id" value="{{deviceUser.isvClientId}}" placeholder="{{deviceUser.isvClientId}}" />
                          </td>
                          <td>
                              <input class="form-control form-control-sm" type="text" [attr.id]="'input-isv-client-secret-' + deviceUser.id" value="{{deviceUser.isvClientSecret}}" placeholder="{{deviceUser.isvClientSecret}}" />
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div *ngIf="event.hasSumUp && hasSumUpUsers()" style="width: 50%;">
                <h6 class="card-title">
                  {{'event.details.configureSumUp' | translate}}
                </h6>
                <div class="table-responsive" style="width:auto;">
                  <table class="table table-sm">
                    <thead>
                      <tr>
                        <th>{{'event.details.device' | translate}}</th>
                        <th>Affiliate Key *</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let deviceUser of event.deviceUsers">
                        <ng-container *ngIf="deviceUser.enableSumUp">
                          <td>{{deviceUser.name}}</td>
                          <td>
                              <input class="form-control form-control-sm" type="text" [attr.id]="'input-affiliate-key-' + deviceUser.id" value="{{deviceUser.affiliateKey}}" placeholder="{{deviceUser.affiliateKey}}" />
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div style="display: flex;">
              <div *ngIf="hasFiscalPrinters()">
                <h6 class="card-title">{{'event.details.configureFiscalPrinters' | translate}}</h6>
                <span class="pr-1" style="white-space: break-spaces">{{'event.details.configureFiscalPrinters_description' | translate}}</span>
                <div class="table-responsive">
                  <table class="table table-sm">
                    <thead>
                    <tr>
                      <th>{{'event.details.device' | translate}}</th>
                      <th>{{'event.details.ipAddress' | translate}} *</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let deviceUser of event.deviceUsers">
                      <ng-container *ngIf="deviceUser.hasFiscalPrinter">
                        <td>{{deviceUser.name}}</td>
                        <td><input
                            class="form-control form-control-sm"
                            type="text"
                            [attr.id]="'input-ip-address-' + deviceUser.id"
                            value="{{deviceUser.fiscalPrinterIP}}"
                            placeholder="{{deviceUser.fiscalPrinterIP}}"/>
                        </td>
                      </ng-container>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div style="float: right;">
              <button class="btn btn-primary" (click)="updateDevicesUser()">
                <i class="fa fa-save"></i>{{ 'event.details.update' | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card" style="margin-top: 10px;" *ngIf='event.printers.length+selectablePrinterCount != 0'>
        <div class="card-header">
          {{'event.details.ownPrinterInformations' | translate}}
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-sm ">
                <thead>
                    <tr>
                        <th>{{'event.details.printerName' | translate}}</th>
                        <th>{{'event.details.printerType' | translate}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let printer of event.printers " [className]="checkOwnPrinter(printer.id) ? 'own-printer' : 'rented-printer'">
                        <td>{{printer.name}}</td>
                        <td>{{printer.printerType.name}}</td>
                        <td class="text-right">
                            <button type="button" style="margin-right:-10px" class="btn btn-sm btn-primary" (click)="addRemovePrinterToCompanyClick(printer.id,'del')" *ngIf="checkOwnPrinter(printer.id)"><th>{{'event.details.printerRemoveBtn' | translate}}</th></button>
                            <span class="rented-label" *ngIf="!checkOwnPrinter(printer.id)">{{'event.details.rented' | translate}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="card" *ngIf='selectablePrinterCount > 0'>
                <div class="card-body" style="padding-bottom: 0px;">
                    <div class="form-row align-items-center">
                        <div class="col">
                          <select class="form-control" [(ngModel)]="selectedPrinter">
                            <option *ngFor="let option of printerListOptions" [ngValue]="option.value">{{option.label}}</option>
                          </select>
                        </div>
                        <div class="col">
                            <button type="button" class="btn btn-sm btn-default"
                            (click)="addRemovePrinterToCompanyClick(0,'add')"
                            ><th>{{'event.details.printerAssignBtn' | translate}}</th></button>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-3" *ngIf="event.routers.length > 0">
        <div class="card-header">
          {{'event.details.routerInformations' | translate}}
        </div>
        <div class="card-body ">
          <div class="table-respnsive ">
            <table class="table table-sm ">
              <thead>
                <tr>
                  <th>{{'event.details.routerName' | translate}}</th>
                  <!-- <th>{{'event.details.routerStatus' | translate}}</th>
                            <th>{{'event.details.routerType' | translate}}</th> -->
                  <th>{{'event.details.channel' | translate}}</th>
                  <th>{{'event.details.routerMasterType' | translate}}</th>
                  <th>{{'event.details.networkName' | translate}}</th>
                  <th>{{'event.details.networkPassword' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let router of routers">
                  <td>{{router.name}}</td>
                  <!-- <td>{{router.status}}</td>
                            <td>{{router.routerType.name}}</td> -->
                  <td>{{router.channel}}</td>
                  <td>{{(router.master === 0 ? 'event.details.mainRouter' : 'event.details.slaveRouter') | translate}}
                  </td>
                  <td>{{router.networkName}}</td>
                  <td>{{router.networkPassword}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
