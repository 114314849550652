<div class="wrapper">
    <div class="sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="main-panel">
        <app-top-navbar title="{{event.eventName}}"></app-top-navbar>
        <ng-container *ngIf="enableEventHeader">
          <div class="row no-gutters page-heading d-print-none"
              style="background-color: white; padding: 50px 0px 20px 0px; margin-top:-35px">
              <div class="col">
                  <ul class="list-group list-group-horizontal">
                      <li class="list-group-item justify-content-between col" style="border:none;">
                          <div class="media">
                              <i class="fa fa-clock align-self-start mr-4"
                                  style="font-size: 32px; padding-right: 20px; color: #95cc6b!important; font-weight: normal;"></i>
                              <div class="media-body">
                                  <span class="m-0 text-muted">{{'event.details.status' | translate}}</span>
                                  <h5 class="m-0" style="color: #95cc6b!important;">
                                      <strong
                                          [innerHTML]="event.status | eventStatus:event.startDate:event.endDate"></strong>
                                  </h5>
                              </div>
                          </div>
                      </li>
                      <li class="list-group-item justify-content-between col" style="border:none;">
                          <div class="media">
                              <i class="fa fa-calendar-alt align-self-start mr-4"
                                  style="font-size: 32px; padding-right: 20px; color: #9e5ba1!important; font-weight: normal;"></i>
                              <div class="media-body">
                                  <span class="m-0 text-muted">{{'event.details.startDate' | translate}}</span>
                                  <h5 class="m-0" style="color: #9e5ba1!important; ">
                                      <strong>{{'event.myEvents.' + (event.startDate | date:'E')?.toLowerCase() | translate}} {{event.startDate | date:'dd-MM-yyyy'}}</strong></h5>
                              </div>
                          </div>
                      </li>
                      <li class="list-group-item justify-content-between col" style="border:none;">
                          <div class="media">
                              <i class="fa fa-calendar-alt align-self-start mr-4"
                                  style="font-size: 32px; padding-right: 20px; color: #9e5ba1!important; font-weight: normal;"></i>
                              <div class="media-body">
                                  <span class="m-0 text-muted">{{'event.details.endDate' | translate}}</span>
                                  <h5 class="m-0" style="color: #9e5ba1!important; ">
                                      <strong>{{'event.myEvents.' + (event.endDate | date:'E')?.toLowerCase() | translate}} {{event.endDate | date:'dd-MM-yyyy'}}</strong></h5>
                              </div>
                          </div>
                      </li>
                      <li class="list-group-item justify-content-between col" style="border:none;">
                          <div class="media">
                              <i class="fa fa-car-side align-self-start mr-4"
                                  style="font-size: 32px; padding-right: 20px; color: #2ba8e3!important;"></i>
                              <div class="media-body">
                                  <span class="m-0 text-muted">{{'event.details.pickUpDate' | translate}}</span>
                                  <h5 class="m-0" style="color: #2ba8e3!important;">
                                      <strong>{{'event.myEvents.' + (event.pickUpDate | date:'E')?.toLowerCase() | translate}} {{event.pickUpDate | date:'dd-MM-yyyy'}}</strong></h5>
                              </div>
                          </div>
                      </li>
                      <li class="list-group-item justify-content-between col" style="border:none;">
                          <div class="media">
                              <i class="fa fa-car-side align-self-start mr-4"
                                  style="font-size: 32px; padding-right: 20px; color: #2ba8e3!important;"></i>
                              <div class="media-body">
                                  <span class="m-0 text-muted">{{'event.details.returnDate' | translate}}</span>
                                  <h5 class="m-0" style="color: #2ba8e3!important;">
                                      <strong>{{'event.myEvents.' + (event.returnDate | date:'E')?.toLowerCase() | translate}} {{event.returnDate | date:'dd-MM-yyyy'}}</strong></h5>
                              </div>
                          </div>
                      </li>
                  </ul>
              </div>
          </div>
        </ng-container>
        <ng-container *ngIf="enableSubMenu">
          <ng-container *ngIf="!isEventFinish; else eventFinishMenuTemplate">
              <div class="row no-gutters d-print-none" id="eventDetailsSubMenu">
                  <div class="col">
                      <div class="list-group list-group-horizontal">
                          <a href="event/details/{{event.id}}/advertising" class="list-group-item list-group-item-action border-all"
                              [ngClass]="{'active': activeSubMenu === 'advertising'}"
                              style="border-top-right-radius: 0; border-bottom-right-radius: 0;">
                              <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-1"><strong>1. {{'event.details.step' | translate}}</strong></h5>
                              </div>
                              <small [ngClass]="{'text-muted': activeSubMenu !== 'advertising'}">
                                  {{'event.details.advertising' | translate}}</small>
                          </a>
                          <a href="event/details/{{event.id}}/general" class="list-group-item list-group-item-action border-all"
                              [ngClass]="{'active': activeSubMenu === 'general'}"
                              style="border-left: none; border-top-left-radius: 0; border-bottom-left-radius: 0;">
                              <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-1"><strong>2. {{'event.details.step' | translate}}</strong></h5>
                                  <i class="fa fa-check-double text-success" style="font-size: 20px;"></i>
                              </div>
                              <small [ngClass]="{'text-muted': activeSubMenu !== 'general'}">
                                  {{'event.details.overviewDevices' | translate}}</small>
                          </a>
                          <a href="event/details/{{event.id}}/device-users" class="list-group-item list-group-item-action border-all"
                              [ngClass]="{'active': activeSubMenu === 'devices'}">
                              <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-1"><strong>3. {{'event.details.step' | translate}}</strong></h5>
                                  <i class="fa {{(disableDeviceMenuItem ? 'fa-times text-danger' : 'fa-check-double text-success')}}"
                                      style="font-size: 20px;"></i>
                              </div>
                              <small [ngClass]="{'text-muted': activeSubMenu !== 'devices'}">
                                  {{'event.details.usersAndAccessDatas' | translate}}</small>
                          </a>
                          <a href="event/details/{{event.id}}/price-list" class="list-group-item list-group-item-action border-all"
                              [ngClass]="{'active': activeSubMenu === 'pricelist'}">
                              <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-1"><strong>4. {{'event.details.step' | translate}}</strong></h5>
                                  <i class="fa {{(warningMissingPriceList ? 'fa-exclamation-triangle text-warning' : 'fa-check-double text-success')}}"
                                      style="font-size: 20px;"></i>
                              </div>
                              <small [ngClass]="{'text-muted': activeSubMenu !== 'pricelist'}">
                                  {{'event.details.pricelist' | translate}}</small>
                          </a>
                          <a href="event/details/{{event.id}}/assign-printer-category"
                              class="list-group-item list-group-item-action  border-all {{disableMissingCategoryPrinterAssign ? 'disabled': ''}}"
                              [ngClass]="{'active': activeSubMenu === 'assignPrinterCategory'}">
                              <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-1"><strong>5. {{'event.details.step' | translate}}</strong></h5>
                                  <i class="fa {{(warningMissingCategoryPrinterAssign ? 'fa-exclamation-triangle text-warning' : 'fa-check-double text-success')}}"
                                      style="font-size: 20px;"></i>
                              </div>
                              <small [ngClass]="{'text-muted': activeSubMenu !== 'assignPrinterCategory'}">
                                  {{'event.details.assignCategoryToPrinters' | translate}}</small>
                          </a>

                          <a href="event/details/{{event.id}}/statistics"
                              class="list-group-item list-group-item-action border-all statistic {{disableMissingCategoryPrinterAssign ? 'disabled': ''}}"
                              [ngClass]="{'active': activeSubMenu === 'statistics'}"
                              style="border-top-right-radius: 0; border-bottom-right-radius: 0;">
                              <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-1"><strong>{{'event.details.statistics' | translate}}</strong></h5>
                              </div>
                              <small [ngClass]="{'text-muted': activeSubMenu !== 'statistics'}">
                                {{'event.details.statistics' | translate}}</small>
                          </a>
                          <!-- <a href="event/details/{{event.id}}/delivery-paper" class="list-group-item list-group-item-action" [ngClass]="{'active': activeSubMenu === 'deliveryPaper'}" style="border-right: none; border-top-right-radius: 0; border-bottom-right-radius: 0;" *ngIf="!warningMissingCategoryPrinterAssign">
                              <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-1"><strong>5. {{'event.details.step' | translate}}</strong></h5>
                              </div>
                              <small [ngClass]="{'text-muted': activeSubMenu !== 'deliveryPaper'}">
                                  {{'event.details.deliveryPaper' | translate}}</small>
                          </a> -->
                      </div>
                  </div>
              </div>
          </ng-container>
          <ng-template #eventFinishMenuTemplate>
              <div class="row no-gutters">
                  <div class="col">
                      <div class="list-group list-group-horizontal">
                          <!--
                          <a href="event/details/{{event.id}}/statistics" class="list-group-item list-group-item-action"
                              [ngClass]="{'active': activeSubMenu === 'statistics'}"
                              style="border-right: none; border-top-right-radius: 0; border-bottom-right-radius: 0;">
                              <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-1"><strong>{{'event.details.statistics' | translate}}</strong></h5>
                              </div>
                          </a>
                          -->
                          <a href="event/details/{{event.id}}/statistics" class="list-group-item list-group-item-action"
                              [ngClass]="{'active': activeSubMenu === 'statistics'}"
                              style="border-right: none; border-top-right-radius: 0; border-bottom-right-radius: 0;">
                              <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-1"><strong>{{'event.details.statistics' | translate}}</strong></h5>
                              </div>
                          </a>
                          <a href="event/details/{{event.id}}/general" class="list-group-item list-group-item-action"
                              [ngClass]="{'active': activeSubMenu === 'general'}"
                              style="border-left: none; border-top-left-radius: 0; border-bottom-left-radius: 0;">
                              <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-1"><strong>{{'event.details.overviewDevices' | translate}}</strong></h5>
                              </div>
                          </a>
                      </div>
                  </div>
              </div>
          </ng-template>
        </ng-container>
        <div class="main-content container-fluid">
            <div class="row">
                <div class="col">
                    <router-outlet></router-outlet>
                </div>
            </div>
            <ng-container *ngIf="enableEventFooter">
              <div class="row" style="padding-bottom: 20px;">
                  <ng-container *ngIf="activeSubMenu !== 'statistics'">
                    <div class="col text-right">
                      <div class="btn-group mr-3" role="group" *ngIf="!warningMissingCategoryPrinterAssign">
                          <ng-container *ngIf="currentLastRouteParam !== 'delivery-paper'; else elseTemplate">
                              <a href="event/details/{{event.id}}/delivery-paper" class="btn btn-secondary">
                                  <strong><i class="fa fa-print"></i>
                                      {{'event.details.deliveryPaper' | translate}}</strong>
                              </a>
                          </ng-container>
                          <ng-template #elseTemplate>
                              <button (click)="print()" class="btn btn-secondary d-print-none">
                                  <strong><i class="fa fa-print"></i>
                                      {{'event.details.print' | translate}}</strong>
                              </button>
                          </ng-template>
                      </div>
                      <div class="btn-group" role="group" *ngIf="(activeSubMenu === 'advertising' && !isEventFinish)">
                        <a href="event/details/{{event.id}}/general" class="btn btn-secondary">
                            <strong>2. {{'event.details.step' | translate}} <i class="fa fa-chevron-right"></i></strong>
                        </a>
                      </div>
                      <div class="btn-group" role="group" *ngIf="(activeSubMenu === 'general' && !isEventFinish)">
                          <a href="event/details/{{event.id}}/advertising" class="btn btn-secondary">
                              <strong><i class="fa fa-chevron-left"></i>1. {{'event.details.step' | translate}}</strong>
                          </a>
                          <a href="event/details/{{event.id}}/device-users" class="btn btn-secondary">
                              <strong>3. {{'event.details.step' | translate}} <i class="fa fa-chevron-right"></i></strong>
                          </a>
                      </div>
                      <div class="btn-group" role="group" *ngIf="activeSubMenu === 'devices'">
                          <a href="event/details/{{event.id}}/general" class="btn btn-secondary">
                              <strong><i class="fa fa-chevron-left"></i>2. {{'event.details.step' | translate}}</strong>
                          </a>
                          <a href="event/details/{{event.id}}/price-list" class="btn btn-secondary">
                              <strong>4. {{'event.details.step' | translate}} <i class="fa fa-chevron-right"></i></strong>
                          </a>
                      </div>
                      <div class="btn-group" role="group" *ngIf="activeSubMenu === 'pricelist'">
                          <a href="event/details/{{event.id}}/device-users" class="btn btn-secondary">
                              <strong><i class="fa fa-chevron-left"></i>3. {{'event.details.step' | translate}}</strong>
                          </a>
                          <a href="event/details/{{event.id}}/assign-printer-category"
                              class="btn btn-secondary {{disableMissingCategoryPrinterAssign ? 'disabled': ''}}">
                              <strong>5. {{'event.details.step' | translate}} <i class="fa fa-chevron-right"></i></strong>
                          </a>
                      </div>
                      <div class="btn-group" role="group" *ngIf="activeSubMenu === 'assignPrinterCategory'">
                          <a href="event/details/{{event.id}}/price-list" class="btn btn-secondary">
                              <strong> <i class="fa fa-chevron-left"></i>4. {{'event.details.step' | translate}}</strong>
                          </a>
                      </div>
                    </div>
                  </ng-container>
              </div>
            </ng-container>
        </div>
        <div #notificationContainer></div>
    </div>
</div>
