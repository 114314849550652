import { OnInit, Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LOCAL_STORAGE } from '../../core/constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AccountService } from '../shared/account.service';
import { ValidationUtils } from 'src/app/shared/validation.utils';
import { Client } from 'src/app/shared/models/Client';
import { Company } from 'src/app/shared/models/Company';
import { Country } from 'src/app/shared/models/Country';
import { TranslateService } from '@ngx-translate/core';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { Village } from 'src/app/shared/models/Village';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  public currentLanguage: any;
  public clientForm: FormGroup;
  public companyForm: FormGroup;
  public clientErrorArray = [];
  public companyErrorArray = [];
  public chosenCountry: Country;
  public chosenVillage: Village;
  public currentCompany: Company;
  public newPassword: string;
  public newPasswordRepeat: string;
  public newPasswordErrorMsg = '';
  public newPasswordRepeatErrorMsg = '';
  public dataFetched = false;
  public titleOptions: Option[] = [
    { value: 'Herr', label: 'register.herr' },
    { value: 'Frau', label: 'register.frau' }
  ];
  public missingVillageErrorMsg = '';

  public locationGroupClass = 'col';

  private _clientId: number;
  private _newClient: Client = new Client();
  private _newCompany: Company;

  constructor(private _toastr: ToastrService, private _formBuilder: FormBuilder, public jwtHelper: JwtHelperService,
    private _accountService: AccountService, private _translate: TranslateService) {
    this.currentLanguage = this._translate.currentLang;
    this.clientForm = this._formBuilder.group({
      title: ['Herr', <any>Validators.required],
      firstName: ['', [<any>Validators.required, <any>Validators.minLength(2)]],
      lastName: ['', [<any>Validators.required, <any>Validators.minLength(2)]],
      firstEmail: [{ value: '', disable: true }],
      secondEmail: ['', <any>Validators.email],
      firstPhone: ['', <any>Validators.required],
      secondPhone: ['', <any>Validators.minLength(5)],
      language: ['de', [<any>Validators.required]],
      newsletter: [false]
    });

    this.companyForm = this._formBuilder.group({
      name: ['', [<any>Validators.required, <any>Validators.minLength(2)]],
      firstEmail: ['', [<any>Validators.required, <any>Validators.email]],
      secondEmail: ['', <any>Validators.email],
      firstPhone: ['', <any>Validators.required],
      secondPhone: [''],
      fiscalCode: [''],
      vat: [''],
      pecEmail: ['', <any>Validators.email],
      isdNumber: [''],
      address: ['', <any>Validators.required],
      postalCode: ['', <any>Validators.required],
      city: [''],
      village: [''],
      country: [''],
    });
  }

  ngOnInit() {
    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    const tokenData = this.jwtHelper.decodeToken(accessToken).data;
    this._clientId = tokenData.id;

    this._accountService.getSpecificClientById(this._clientId).subscribe({
      next: (result) => {
        this.clientForm.patchValue(result);
        if (result.company != null) {
          this.currentCompany = result.company;
          this.companyForm.patchValue(result.company);
          this._newCompany = result.company;
        }
        this._newClient = result;
        this.dataFetched = true;
      },
      error: () => console.log('Unable to retrieve client data'),
    });
  }
  onSubmitClientForm() {
    if (this.clientForm.valid) {
      this._newClient = Client.fromDto(this.clientForm.value, this._newClient);
      if (this.clientForm.value.newPassword !== undefined || this.clientForm.value.newPassword !== '') {
        this._newClient.password = this.clientForm.value.newPassword;
      }
      this._accountService.updateClient(this._newClient).subscribe({
        next: () => this._toastr.success(this._translate.instant('account.clientUpdateSuccess')),
        error: () => console.log('Unable to update client data'),
      });
    }
    this.clientErrorArray = ValidationUtils.getFormValidationErrors(this.clientForm, 'account');
  }

  onSubmitCompanyForm() {
    this.missingVillageErrorMsg = '';
    if (this.companyForm.valid) {
      if (!this.chosenCountry) {
        this.locationGroupClass = 'col border-red';
        this.missingVillageErrorMsg = this._translate.instant('account.errorMsg.missingCountry');
        return;
      }
      if (this.chosenCountry.id === 1 && !this.chosenVillage) {
        this.locationGroupClass = 'col border-red';
        this.missingVillageErrorMsg = this._translate.instant('account.errorMsg.missingVillage');
        return;
      }
      this.missingVillageErrorMsg = null;
      this.locationGroupClass = 'col';

      this._newCompany = Company.fromDto(this.companyForm.value, this._newCompany);
      this._newCompany.country = this.chosenCountry;
      this._newCompany.village = this.chosenVillage;
      this._newClient.company = this._newCompany;

      this._accountService.updateCompany(this._newClient.id, this._newClient.company).subscribe({
        next: () => this._toastr.success(this._translate.instant('account.companyAdded')),
        error: () => console.log('Unable to update company data')
      });
    }
    this.companyErrorArray = ValidationUtils.getFormValidationErrors(this.companyForm, 'account');
    console.log(this.companyErrorArray);
  }

  updateClientPassword() {
    this.newPasswordErrorMsg = '';
    this.newPasswordRepeatErrorMsg = '';
    if (!this.newPassword) {
      this.newPasswordErrorMsg = this._translate.instant('account.errorMsg.required');
      return;
    }
    if (!this.newPasswordRepeat) {
      this.newPasswordRepeatErrorMsg = this._translate.instant('account.errorMsg.required');
      return;
    }
    if (this.newPassword !== this.newPasswordRepeat) {
      this.newPasswordRepeatErrorMsg = this._translate.instant('account.errorMsg.passwordNotEqual');
      return;
    }
    this._accountService.changeClientPassword(this._clientId, this.newPassword).subscribe({
      next: () => {
        this.newPassword = '';
        this.newPasswordRepeat = '';
        this._toastr.success(this._translate.instant('account.passwordSuccessUpdate'));
      },
      error: () => console.log('Unable to change password'),
    });
  }

}
