<span>{{'printers.count' | translate}}</span>
<div class="input-container">
  <input class= "col-2" type="number" (change)="onNrPrinterChange($event)" [value]="nrPrinters" max="4" min="1">
  <button class="btn btn-info btn-block btn-fill btn-sm ml-2 col-2">OK</button>
</div>
<div>
  <kendo-stepper
    *ngIf="forms.length > 1"
    [steps]="steps"
    stepType="indicator"
    [style]="{'padding': '5px 0'}"
    [(currentStep)]="currentStep"
  >
  </kendo-stepper>
  <ng-container *ngFor="let form of forms; let i = index">
    <ng-container *ngIf="currentStep === i">
      <hr *ngIf="nrPrinters === 1"/>
      <span *ngIf="nrPrinters> 1" class="font-bold mt-3">{{'printers.fill' | translate}} {{ i + 1 }}</span>
      <div class="row">
        <app-form-group-select
          class="col"
          [formGroup]="form"
          [options]="typeOptions"
          disabled="true"
          required="true"
          id="type"
          name="type"
          label="{{'printers.type' | translate}} *"
          formControlNameValue="type"
          ngDefaultControl>
        </app-form-group-select>
        <app-form-group-select
          class="col"
          [formGroup]="form"
          [options]="brandOptions"
          disabled="true"
          required="true"
          id="brand"
          name="brand"
          label="{{'printers.brand' | translate}} *"
          formControlNameValue="brand"
          ngDefaultControl>
        </app-form-group-select>
      </div>
      <app-form-group-input
        [formGroup]="form"
        required="true"
        id="name"
        name="name"
        label="{{'printers.name' | translate}} *"
        inputType="text"
        placeHolder="{{'printers.name' | translate}}"
        formControlNameValue="name"
        ngDefaultControl>
      </app-form-group-input>
      <app-form-group-input
        [formGroup]="form"
        id="macAddress"
        name="{{'printers.macAddress' | translate}}"
        label="{{'printers.macAddress' | translate}} *"
        required="true"
        inputType="text"
        formControlNameValue="macAddress"
        placeHolder="0B:3C:39:M3:2A:3B"
        ngDefaultControl>
      </app-form-group-input>
    </ng-container>
  </ng-container>
  <div class="row m-0"
       [ngClass]="{'justify-content-between': currentStep > 0, 'float-right': nrPrinters !== 1 && currentStep === 0}">
    <button class="btn btn-info btn-link" *ngIf="currentStep > 0" (click)="onBackClick()">
      {{ 'printers.back' | translate }}
    </button>
    <button class="btn btn-info btn-fill" *ngIf="currentStep < (forms.length - 1)" (click)="onNextClick()">
      {{ 'printers.next' | translate }}
    </button>
    <ng-container *ngIf="currentStep === (forms.length - 1) || forms.length === 1">
      <button class="btn btn-info btn-fill" (click)="createPrinters()" [ngClass]="{'btn-block': nrPrinters === 1}">
        {{ (this.nrPrinters === 1 ? 'printers.add' : 'printers.add_plural') | translate }}
      </button>
    </ng-container>
  </div>

</div>
