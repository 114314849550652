
export class EventCost {
  vat: number;
  totalDeviceTypePrice: number;
  totalFiscalPrinterPrice: number;
  totalPrinterPrice: number;
  totalRouterTypePrice: number;
  totalSoftwareTypePrice: number;
  totalUpsTypePrice: number;
  totalAdditionalCost: number;
  insuranceCost: number;
  static fromDto(e): EventCost {
    const eventCost = new EventCost();
    if (!e) {
      return null;
    }
    eventCost.vat = e.vat;
    eventCost.totalDeviceTypePrice = e.totalDeviceTypePrice;
    eventCost.totalFiscalPrinterPrice = e.totalFiscalPrinterPrice;
    eventCost.totalPrinterPrice = e.totalPrinterPrice;
    eventCost.totalRouterTypePrice = e.totalRouterTypePrice;
    eventCost.totalSoftwareTypePrice = e.totalSoftwareTypePrice;
    eventCost.totalUpsTypePrice = e.totalUpsTypePrice;
    eventCost.totalAdditionalCost = e.totalAdditionalCost;
    eventCost.insuranceCost = e.insuranceCost;
    return eventCost;
  }

  static fromDtoPrice(e): number {
    const eventCost = this.fromDto(e);
    if (!e) {
      return null;
    }
    let price = 0;
    price += eventCost.totalPrinterPrice;
    price += eventCost.totalFiscalPrinterPrice;
    price += eventCost.totalUpsTypePrice;
    price += eventCost.totalDeviceTypePrice;
    price += eventCost.totalRouterTypePrice;
    price += eventCost.totalSoftwareTypePrice;
    price += eventCost.totalAdditionalCost;
    // don't add insurance... added already in the totalAdditionalCost
    return price;
  }
}
