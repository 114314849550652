<span class="control-label col-form-label-sm">{{'printers.count_rented' | translate}}</span>
    <div class="input-container">
    <input class= "col-2" type="number" (change)="onNrPrinterChange($event)" [value]="nrPrinters" max="4" min="1">
    <button class="btn btn-info btn-block btn-fill btn-sm ml-2 col-2">OK</button>
    </div>
    <kendo-stepper
        *ngIf="forms.length > 1"
        [steps]="steps"
        stepType="indicator"
        [style]="{'padding': '5px 0'}"
        [(currentStep)]="currentStep"
    >
    </kendo-stepper>

<ng-container *ngFor="let form of forms; let i = index">
    <ng-container *ngIf="currentStep === i">
      <form [formGroup]="form">
        <label class="control-label col-form-label-sm" for="searchForm">
          {{ 'printers.insertRentingCode' | translate }}
        </label>
        <div class="input-group">
          <input id="searchForm"
                 name="searchForm"
                 type="text"
                 formControlName="search"
                 class="form-control"
                 style="height: auto"
                 [ngClass]="{'form-control-sm': printers, 'is-invalid': errorMsg}"
                 placeholder="05MD4MM02PR9"
          />
          <div class="input-group-append">
            <button class="btn btn-fill btn-info"
                    type="button"
                    style="height: auto"
                    [ngClass]="{'btn-sm': printers}"
                    (click)="submit()">
              {{ 'printers.search' | translate }}
            </button>
          </div>
        </div>
        <small class="k-form-error" [hidden]="!errorMsg">{{ errorMsg | translate }}</small>
        <hr>
      </form>
      <ng-container *ngIf="printers[i]">
        <app-printer-form formType="read" [printer]="printers[i]" [typeOptions]="typeOptions"></app-printer-form>
      </ng-container>
    </ng-container>
</ng-container>

<ng-container>
    <div class="row m-0"
            [ngClass]="{'justify-content-between': currentStep > 0, 'float-right': nrPrinters !== 1 && currentStep === 0}">
        <button class="btn btn-info btn-link" *ngIf="currentStep > 0" (click)="onBackClick()">
            {{ 'printers.back' | translate }}
        </button>
        <button class="btn btn-info btn-fill" *ngIf="currentStep < (forms.length - 1)" (click)="onNextClick()">
            {{ 'printers.next' | translate }}
        </button>
        <ng-container *ngIf="currentStep === (forms.length - 1) || forms.length === 1">
            <button class="btn btn-info btn-fill" (click)="addPrinters()" [ngClass]="{'btn-block': nrPrinters === 1}">
                {{ (this.nrPrinters === 1 ? 'printers.add' : 'printers.add_plural') | translate }}
            </button>
        </ng-container>
    </div>
</ng-container>
