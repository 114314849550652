import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { URL_BASE } from 'src/app/core/constants';
import { HttpUtils } from 'src/app/shared/http-utils';
import { StatisticTurnover } from 'src/app/shared/models/StatisticsTurnover';
import { environment } from 'src/environments/environment';


enum EndpointRoutes {
  STATISTIC_TURNOVER = '/turnOver/get',
  STATISTIC_TURNOVER_DELETEDATA = '/turnOver/delete'
}

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  private _headers: any;

  constructor(private _http: HttpClient) {
  }

  public getTurnoverData(
    eventId: number, priceListId: number, startDate: string, endDate: string,
    deviceId?: number[], deviceTypeId?: number, table?: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    let params = new HttpParams();
    params = params.append('eventId', eventId);
    if (priceListId) {
      params = params.append('priceListId', priceListId);
    }
    params = params.append('startDate', startDate.toString());
    params = params.append('endDate', endDate.toString());

    if (deviceId) {
      params = params.append('deviceId', JSON.stringify(deviceId));
    } else {
      params = params.append('deviceId', JSON.stringify([]));
    }
    if (deviceTypeId) {
      params = params.append('deviceTypeId', deviceTypeId);
    }
    if (table) {
      params = params.append('table', table);
    }
    return this._http.get<any>(URL_BASE + EndpointRoutes.STATISTIC_TURNOVER, { headers: headers, params: params }).pipe(
      map((response: any) => response)
    );
  }

  public deleteTurnoverData(eventId: number): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.delete(URL_BASE + EndpointRoutes.STATISTIC_TURNOVER_DELETEDATA, { headers: headers, params: { eventId: eventId }
    });
  }
}
