<div class="container">
    <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-6 mx-auto">
            <div class="text-center mt-5 mb-3">
                <img class="logo" src="/assets/famulus_logo.png" alt="Famulus" height="100">
            </div>
            <div class="card card-signin">
                <div class="card-body">
                    <div class="card-title text-center">
                        <h5>{{'login.loginTitle' | translate}}</h5>
                        <p class="text-muted"><small>{{'login.loginTitleMsg' | translate}}</small></p>
                    </div>
                    <div class="shadow p-3 mb-3 bg-white border border-success rounded" *ngIf="showRegisterSuccessMsg">
                        <button type="button" class="close" aria-label="Close"
                            (click)="showRegisterSuccessMsg = false;">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <strong class="text-success">{{'login.successRegistrationMsg' | translate}}</strong>
                    </div>
                    <div class="shadow p-3 mb-3 bg-white border border-success rounded" *ngIf="showActivationSuccessMsg">
                        <button type = "button" class="close" aria-label="Close"
                          (click)="showActivationSuccessMsg = false;">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <strong class="text-success">{{'login.successActivationMsg' | translate}}</strong>
                    </div>
                    <form [formGroup]="loginForm" (ngSubmit)="onSignin()" class="needs-validation">
                        <app-form-group-input inputType="email" label="{{'login.email' | translate}}" name="email"
                            id="email" placeHolder="{{'login.email' | translate}}" [required]="true"
                            formControlNameValue="email" [error]="errorArray['email']" [formGroup]="loginForm"
                            ngDefaultControl></app-form-group-input>
                        <app-form-group-input inputType="password" label="{{'register.password' | translate}}"
                            name="password" id="password" placeHolder="{{'login.password' | translate}}"
                            [required]="true" formControlNameValue="password" [error]="errorArray['password']"
                            [formGroup]="loginForm" ngDefaultControl>
                        </app-form-group-input>
                        <div class="text-center mt-4">
                            <button class="btn btn-primary btn-signin btn-block btn-sm"
                                type="submit">{{'login.login-btn' | translate}}</button>
                        </div>
                    </form>
                    <div class="text-center my-4">
                        <a href="/recovery-password" class="recover-password">{{'login.forgot' | translate}}</a>
                        <p class="text-muted text-center"><small>{{'login.registrationQuestion' | translate}}</small>
                        </p>
                    </div>
                    <a href="/register"
                        class="btn btn-secondery btn-signin btn-block btn-sm">{{'login.register' | translate}}</a>
                    <p class="text-muted text-center mt-5" style="margin-bottom: 0px;"><small>Copyright RR-Solutions
                            {{currentDate | date: 'yyyy'}}</small></p>
                </div>
            </div>
        </div>
    </div>
</div>
