import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-group-number-input',
  templateUrl: './form-group-number-input.component.html',
  styleUrls: ['./form-group-number-input.component.css']
})
export class FormGroupNumberInputComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  model = 0;

  @Input()
  offset = 1;

  @Input()
  errorText = '';

  @Output()
  modelChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  negativeModelChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  positiveModelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }
  ngOnInit() {
  }

  changeValueClickEvent(negative) {
    if (negative) {
      this.model = (this.model >= 1 ? (this.model - this.offset) : this.model);
      this.modelChange.emit(this.model);
      this.negativeModelChange.emit(this.model);
    } else {
      this.model = (this.model + this.offset);
      this.modelChange.emit(this.model);
      this.positiveModelChange.emit(this.model);
    }
  }

}
