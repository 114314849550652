<form [formGroup]="form">
  <label class="control-label col-form-label-sm" for="searchForm">
    {{ 'printers.insertRentingCode' | translate }}
  </label>
  <div class="input-group">
    <input id="searchForm"
           name="searchForm"
           type="text"
           formControlName="search"
           class="form-control"
           style="height: auto"
           [ngClass]="{'form-control-sm': printer, 'is-invalid': errorMsg}"
           placeholder="05MD4MM02PR9"
    />
    <div class="input-group-append">
      <button class="btn btn-fill btn-info"
              type="button"
              style="height: auto"
              [ngClass]="{'btn-sm': printer}"
              (click)="submit()">
        {{ 'printers.search' | translate }}
      </button>
    </div>
  </div>
  <small class="k-form-error" [hidden]="!errorMsg">{{ errorMsg | translate }}</small>
  <hr>
</form>
<ng-container *ngIf="printer">
  <app-printer-form formType="read" [printer]="printer" [typeOptions]="typeOptions"></app-printer-form>
</ng-container>
