<div class="wrapper">
  <div class="sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="main-panel">
    <app-top-navbar title="{{ 'offers.request.eventRequest' | translate }}"></app-top-navbar>
    <div class="main-content container-fluid">
      <div class="card">
        <div class="row">
          <div class="col">
            <div class="content">
              <fieldset class="scheduler-border">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <app-simple-form-group-input
                      label="{{ 'offers.request.eventTitle' | translate }} *"
                      [helpText]="'offers.request.eventTitleHelpText' | translate"
                      [(model)]="eventOffer.eventName">
                    </app-simple-form-group-input>
                  </div>
                  <div class="col-md-6 col-lg-3">
                    <app-form-group-datepicker
                      [label]="'offers.request.startDate' | translate"
                      [helpText]="'offers.request.startDateHelpText' | translate"
                      [(model)]="eventStartDate"
                      (modelChange)="onDateChange('startDate')"
                      [minDate]="minDateObj">
                    </app-form-group-datepicker>
                  </div>
                  <div class="col-md-6 col-lg-3">
                    <app-form-group-datepicker
                      [label]="'offers.request.endDate' | translate"
                      [helpText]="'offers.request.endDateHelpText' | translate"
                      [(model)]="eventEndDate"
                      (modelChange)="onDateChange('endDate')"
                      [minDate]="minDateObj">
                    </app-form-group-datepicker>
                  </div>
                </div>
                <span class="h5">{{"offers.request.eventLocation" | translate }}</span>
                <div class="row">
                  <div class=" col-md-12">
                    <app-select-location-group
                      (onVillageChosen)="chosenVillage = $event" (onCountryChosen)="onCountryChosen($event)">
                    </app-select-location-group>
                  </div>
                </div>
                <div class="row">
                  <div *ngIf="showVillageFraction" class="col-lg-3 col-md-6">
                    <div class="form-group">
                      <label class="control-label col-form-label-sm">{{"offers.request.villageFraction" | translate}}</label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="eventOffer.zone" [disabled]="chosenVillage === undefined"/>
                      <small class="form-text text-muted">
                        {{"offers.request.villageFractionHelpText" | translate }}
                      </small>
                    </div>
                  </div>
                </div>
                <div *ngIf="showGeneralLocationInput" class="col p-0">
                  <div class="form-group">
                    <label class="control-label col-form-label-sm">{{"offers.request.village" | translate }} *</label>
                    <input type="text" class="form-control form-control-sm" [(ngModel)]="eventOffer.zone"/>
                    <small class="form-text text-muted">{{"offers.request.villageHelpText" | translate }}</small>
                  </div>
                </div>
                <div class="row">
                  <div class="col"><small class="text-muted">{{"requiredField" | translate}}</small></div>
                </div>
                <div class="row">
                  <div class="col">
                    <strong class="text-danger" *ngIf="eventInformationsError.length > 0">{{ eventInformationsError }}</strong>
                  </div>
                </div>
                <div class="row" style="margin-top: 20px">
                  <div class="col">
                    <div class="text-termconditions h5">{{"offers.request.acceptTermsAndConditionsTitle" | translate}}</div>
                    <div>
                      <a href="../../../assets/downloads/Privacy_{{this.currentLanguage}}.pdf" target="_blank" download="Privacy_{{this.currentLanguage}}.pdf" style="display:inline-block; padding-bottom:10px;"><h6><b><i class="fa fa-file-pdf"></i> {{'offers.request.acceptPrivacyDownload' | translate}}</b></h6></a>
                      &nbsp;&nbsp;|&nbsp;&nbsp;
                      <a href="../../../assets/downloads/AGB_Famulus_{{this.currentLanguage}}.pdf" target="_blank" download="AGBFamulus_{{this.currentLanguage}}.pdf" style="display:inline-block; padding-bottom:10px;"><h6><b><i class="fa fa-file-pdf"></i> {{'offers.request.acceptTermsAndConditionsDownload' | translate}}</b></h6></a>
                    </div>
                    <div class="row" style="padding:0 37px;">
                      <div class="custom-control custom-switch col-12" style="padding:15px;">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customSwitchTermsAndConditions"
                          [value]="true"
                          [(ngModel)]="acceptTermsAndConditions"
                          (change)="manageTermsAndConditions($event)"/>
                        <label class="custom-control-label" for="customSwitchTermsAndConditions" style="padding-top: 3px">
                          <strong>{{"offers.request.acceptTermsAndConditionsText" | translate}} *</strong>
                        </label>
                      </div>
                      <div class="custom-control custom-switch col-12" style="padding:15px;">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customSwitchTermsAndConditionsPoint4"
                          [value]="true"
                          [(ngModel)]="acceptTermsAndConditionsPoint4"/>
                        <label
                          class="custom-control-label"
                          for="customSwitchTermsAndConditionsPoint4"
                          style="padding-top: 3px">
                          <strong>{{"offers.request.acceptTermsAndConditionsPoint4Text" | translate}} *</strong>
                        </label>
                      </div>
                      <div class="custom-control custom-switch col-12" style="padding:15px;">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customSwitchTermsAndConditionsPoint6"
                          [value]="true"
                          [(ngModel)]="acceptTermsAndConditionsPoint6"/>
                        <label
                          class="custom-control-label"
                          for="customSwitchTermsAndConditionsPoint6"
                          style="padding-top: 3px">
                          <strong>{{"offers.request.acceptTermsAndConditionsPoint6Text" | translate}} *</strong>
                        </label>
                      </div>
                      <div class="custom-control custom-switch col-12" style="padding:15px;">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customSwitchTermsAndConditionsPoint9"
                          [value]="true"
                          [(ngModel)]="acceptTermsAndConditionsPoint9"/>
                        <label
                          class="custom-control-label"
                          for="customSwitchTermsAndConditionsPoint9"
                          style="padding-top: 3px">
                          <strong>{{"offers.request.acceptTermsAndConditionsPoint9Text" | translate}} *</strong>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-top: 20px">
                  <div class="col">
                    <div class="text-isadvertising h5">{{"offers.request.isAdvertisingText" | translate}}</div>
                    <div class="custom-control custom-switch">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customSwitch1"
                        [value]="true"
                        [(ngModel)]="disableAdvertising"/>
                      <label class="custom-control-label" for="customSwitch1" style="padding-top: 3px">
                        <strong>{{"offers.request.disallowAdvertisingText" | translate}}</strong>
                      </label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
        <ng-container *ngIf="acceptTermsAndConditions && acceptTermsAndConditionsPoint4 && acceptTermsAndConditionsPoint6 && acceptTermsAndConditionsPoint9">
          <div class="row" *ngIf="eventOffer.fullPackageRenting">
            <div class="col">
              <div class="content pt-0">
                <div class="row">
                  <div class="offset-lg-4 col-lg-4 col-md-6 offset-md-3 text-center">
                    <div class="card border" [ngClass]="{'shadow border-dark': eventOffer.delivery == false}">
                      <div class="card-header">
                        <h4 class="my-0 font-weight-normal">{{ "offers.request.pickUpDevices" | translate }}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="eventOffer.fullPackageRenting">
            <div class="col">
              <div class="content">
                <ng-container *ngIf="eventOffer.delivery === false">
                  <div class="row">
                    <div class="col text-center" *ngFor="let pickupPoint of pickupPoints">
                      <div class="card border" [ngClass]="{'shadow border-dark':choosenPickupPoint === pickupPoint.id}">
                        <div class="card-body">
                          <div><strong>FAMULUS {{ pickupPoint.nameLangKey | translate }}</strong></div>
                          <div>{{ pickupPoint.addressLangKey | translate }}</div>
                          <div>{{ pickupPoint.cityLangKey | translate }}</div>
                        </div>
                        <div class="card-footer">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="location"
                              id="location-{{ pickupPoint.name }}"
                              [value]="pickupPoint.id"
                              (change)="onPickupPointChange(pickupPoint.id)"
                              [(ngModel)]="choosenPickupPoint"
                              style="margin-top: 3px"/>
                            <label class="form-check-label" [htmlFor]="'location-{{ pickupPoint.name }}'">
                              {{ pickupPoint.nameLangKey | translate }}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <strong class="text-danger" *ngIf="pickupLocationError.length > 0">{{pickupLocationError}}</strong>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="eventOffer.delivery === true">
                  <div class="row">
                    <div class="offset-2 col-8">
                      <fieldset class="scheduler-border">
                        <legend class="scheduler-border">{{ "offers.request.deliveryAddressTitle" | translate }}</legend>
                        <div class="row">
                          <div class="col-4">
                            <app-form-group-select
                              label="{{ 'offers.request.country' | translate }} *"
                              name="language"
                              id="language"
                              formControlNameValue="country"
                              [options]="countriesOptions"
                              [formGroup]="deliveryAddressForm"
                              ngDefaultControl>
                            </app-form-group-select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <app-form-group-input
                              inputType="text"
                              label="{{'offers.request.firstLastName' | translate}} *"
                              name="firstLastName"
                              id="firstLastName"
                              formControlNameValue="firstLastName"
                              [error]="deliveryAddressErrorArray['firstLastName']"
                              [formGroup]="deliveryAddressForm"
                              ngDefaultControl>
                            </app-form-group-input>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label class="control-label col-form-label-sm">
                                {{"offers.request.address" | translate}} *</label>
                              <div class="row no-gutters">
                                <div class="col" [formGroup]="deliveryAddressForm">
                                  <input type="text" class="form-control form-control-sm {{
                                      deliveryAddressErrorArray['address']
                                        ? 'is-invalid'
                                        : ''
                                    }}"
                                    formControlName="address"/>
                                  <div class="invalid-feedback" [hidden]="!deliveryAddressErrorArray['address']">
                                    {{deliveryAddressErrorArray["address"]| translate }}
                                  </div>
                                </div>
                                <div class="col" [formGroup]="deliveryAddressForm">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="{{'offers.request.addressDetailsPlaceHolder' | translate}}"
                                    formControlName="addressDetails"/>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <app-form-group-input
                              inputType="text"
                              label="{{ 'offers.request.city' | translate }} *"
                              name="city"
                              id="city"
                              placeHolder="{{'offers.request.country' | translate}}"
                              formControlNameValue="city"
                              [error]="deliveryAddressErrorArray['city']"
                              [formGroup]="deliveryAddressForm"
                              ngDefaultControl>
                            </app-form-group-input>
                          </div>
                          <div class="col">
                            <app-form-group-input
                              inputType="text"
                              label="{{'offers.request.province' | translate}} *"
                              name="province"
                              id="province"
                              placeHolder="{{'offers.request.province' | translate}}"
                              formControlNameValue="province"
                              [error]="deliveryAddressErrorArray['province']"
                              [formGroup]="deliveryAddressForm"
                              ngDefaultControl>
                            </app-form-group-input>
                          </div>
                          <div class="col">
                            <app-form-group-input
                              inputType="text"
                              label="{{'offers.request.postCode' | translate}} *"
                              name="postcode"
                              id="postcode"
                              placeHolder="{{'offers.request.postCode' | translate}}"
                              formControlNameValue="postcode"
                              [error]="deliveryAddressErrorArray['postcode']"
                              [formGroup]="deliveryAddressForm"
                              ngDefaultControl>
                            </app-form-group-input>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <app-form-group-input
                              inputType="text"
                              label="{{ 'offers.request.phone' | translate }} *"
                              name="phone"
                              id="phone"
                              placeHolder="{{'offers.request.phoneHelpText' | translate}}"
                              formControlNameValue="phone"
                              [error]="deliveryAddressErrorArray['phone']"
                              [formGroup]="deliveryAddressForm"
                              ngDefaultControl>
                            </app-form-group-input>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="eventOffer.delivery !== undefined && chosenCountry?.id">
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="content">
                    <div class="card border">
                      <div class="card-header">
                        <div class="row">
                          <div class="offset-lg-2 col-lg-8 col-md-12 text-center">
                            <h2 class="mt-0 mb-0">{{ "offers.request.hardwareRenting" | translate }}</h2>
                            <div class="clearfix mt-3 mb-3"></div>
                            <div class="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="customRadioInline2"
                                name="customRadioInline1"
                                [(ngModel)]="eventOffer.fullPackageRenting"
                                [value]="true"
                                (change)="onRentHardwareRadioButtonChange()"
                                class="custom-control-input"/>
                              <label
                                class="custom-control-label"
                                for="customRadioInline2"
                                style="padding-top: 1px; font-size: 16px">
                                <strong>{{"offers.request.hardwareRentingYes" | translate}}</strong>
                              </label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="customRadioInline1"
                                name="customRadioInline1"
                                [(ngModel)]="eventOffer.fullPackageRenting"
                                [value]="false"
                                (change)="onRentHardwareRadioButtonChange()"
                                class="custom-control-input"/>
                              <label
                                class="custom-control-label"
                                for="customRadioInline1"
                                style="padding-top: 1px; font-size: 16px">
                                <strong>{{"offers.request.hardwareRentingNo" | translate}}</strong>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <strong class="text-danger" *ngIf="rentHardwareError.length > 0">{{rentHardwareError}}</strong>
                          </div>
                        </div>
                      </div>
                      <div class="card-body" [hidden]="hidePackagesContent">
                        <div class="row mt-3 px-2">
                          <div class="row">
                            <div class="col-lg col-md-4">
                              <div class="card m-0">
                                <h5 class="card-title text-center">
                                  <ng-container *ngIf=" eventOffer.fullPackageRenting; else elseTemplate">
                                    <strong>{{"offers.request.waiters" | translate}}</strong>
                                  </ng-container>
                                  <ng-template #elseTemplate>
                                    <strong>
                                      {{"offers.request.softwareLicense" | translate }}
                                      {{"offers.request.waiters" | translate }}
                                    </strong>
                                    <!-- Kellner Software Lizenz -->
                                  </ng-template>
                                </h5>
                                <ul class="list-group list-group-flush">
                                  <li class="list-group-item p-0 border-bottom-0">
                                    <app-form-group-number-input
                                      [(model)]="eventOffer.softwareWaiter"
                                      (modelChange)="onSoftwareLicenseQuantityChange('waiter')">
                                    </app-form-group-number-input>
                                  </li>
                                </ul>
                                <div class="card-body p-0">
                                  <div class="media">
                                    <div class="media-body text-muted">{{ "offers.request.waiterDesc" | translate }}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg col-md-4 m-0">
                              <div class="card">
                                <h5 class="card-title text-center">
                                  <ng-container *ngIf="eventOffer.fullPackageRenting; else cashRegistersTemplate">
                                    <strong>{{"offers.request.cashRegisters" | translate}}</strong>
                                  </ng-container>
                                  <ng-template #cashRegistersTemplate>
                                    <strong>
                                      {{"offers.request.softwareLicense" | translate}}
                                      {{"offers.request.cashRegisters" | translate }}
                                    </strong>
                                  </ng-template>
                                </h5>
                                <ul class="list-group list-group-flush">
                                  <li class="list-group-item p-0 border-bottom-0">
                                    <app-form-group-number-input
                                      [(model)]="eventOffer.softwareCashRegister"
                                      (negativeModelChange)="
                                        onSoftwareLicenseQuantityChange('cashRegister',true)"
                                      (positiveModelChange)="
                                        onSoftwareLicenseQuantityChange('cashRegister',false)">
                                    </app-form-group-number-input>
                                  </li>
                                </ul>
                                <div class="card-body p-0">
                                  <div class="media">
                                    <div class="media-body text-muted">
                                      {{"offers.request.cashRegisterDesc"| translate}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg col-md-4 m-0">
                              <div class="card">
                                <h5 class="card-title text-center">
                                  <ng-container *ngIf="eventOffer.fullPackageRenting; else mainDevicesTemplate">
                                    <strong>{{"offers.request.mainDevices" | translate}}</strong>
                                  </ng-container>
                                  <ng-template #mainDevicesTemplate>
                                    <strong>
                                      {{"offers.request.softwareLicense" | translate}}
                                      {{"offers.request.mainDevices" | translate}}</strong
                                    >
                                  </ng-template>
                                </h5>
                                <ul class="list-group list-group-flush">
                                  <li class="list-group-item p-0 border-bottom-0">
                                    <div class="form-group row" style="height: 49px">
                                      <div class="col text-center">
                                        <div class="form-check form-check-inline" style="padding-top: 25px">
                                          <input
                                            class="form-check-input"
                                            type="radio"
                                            name="softwareLicenseCheck"
                                            [(ngModel)]="eventOffer.softwareMainDevice"
                                            (change)="onSoftwareLicenseQuantityChange('mainDevice')"
                                            id="softwareLicenseCheckTrue"
                                            [value]="1"/>
                                          <label class="form-check-label" for="softwareLicenseCheckTrue">
                                            {{"offers.request.yes" | translate }}
                                          </label>
                                        </div>
                                      </div>
                                      <div class="col text-center">
                                        <div class="form-check form-check-inline" style="padding-top: 25px">
                                          <input
                                            class="form-check-input"
                                            type="radio"
                                            name="mainDeviceCheck"
                                            [(ngModel)]="eventOffer.softwareMainDevice"
                                            (change)="onSoftwareLicenseQuantityChange('mainDevice')"
                                            id="mainDeviceCheckFalse"
                                            [value]="0"/>
                                          <label class="form-check-label" for="mainDeviceCheckFalse">
                                            {{"offers.request.no" | translate }}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                                <div class="card-body p-0">
                                  <div class="media">
                                    <div class="media-body text-muted">
                                      {{"offers.request.mainDeviceDesc" | translate}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg col-md-4 m-0">
                              <div class="card">
                                <h5 class="card-title text-center">
                                  <ng-container *ngIf="eventOffer.fullPackageRenting; else printersTemplate">
                                    <strong>{{"offers.request.printers" | translate}}</strong>
                                  </ng-container>
                                  <ng-template #printersTemplate>
                                    <strong>
                                      {{"offers.request.softwareLicense" | translate }}
                                      {{"offers.request.printers" | translate }}
                                    </strong>
                                  </ng-template>
                                </h5>
                                <ul class="list-group list-group-flush">
                                  <li class="list-group-item p-0 border-bottom-0">
                                    <app-form-group-number-input
                                      [(model)]="eventOffer.softwarePrinter"
                                      (modelChange)="onSoftwareLicenseQuantityChange('printer')">
                                    </app-form-group-number-input>
                                  </li>
                                </ul>
                                <div class="card-body p-0">
                                  <div class="media">
                                    <div class="media-body text-muted">{{"offers.request.printersDesc" | translate }}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row"
                               [style]="{'width': chosenCountry?.id === 1 && eventOffer.fullPackageRenting ? '50%' : '25%'}">
                            <div class="col-lg col-md-4 m-0" *ngIf="chosenCountry?.id === 1">
                                <div class="card">
                                  <h5 class="card-title text-center">
                                    <ng-container *ngIf="eventOffer.fullPackageRenting; else fiscalPrinterTemplate">
                                      <strong>{{"offers.request.fiscalPrinter" | translate}}</strong>
                                    </ng-container>
                                    <ng-template #fiscalPrinterTemplate>
                                      <strong>
                                        {{"offers.request.softwareLicense" | translate }}
                                        {{"offers.request.fiscalPrinter" | translate }}
                                      </strong>
                                    </ng-template>
                                  </h5>
                                  <ul class="list-group list-group-flush">
                                    <li class="list-group-item p-0 border-bottom-0">
                                      <app-form-group-number-input
                                        [(model)]="eventOffer.softwareFiscalPrinter"
                                        (modelChange)="onSoftwareLicenseQuantityChange('fiscalPrinter')">
                                      </app-form-group-number-input>
                                    </li>
                                  </ul>
                                  <div class="card-body p-0">
                                    <div class="media">
                                      <div class="media-body text-muted">{{"offers.request.fiscalPrinterDesc" | translate }}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            <ng-container *ngIf="eventOffer.fullPackageRenting">
                                <div class="col-lg col-md-4">
                                  <div class="card m-0">
                                    <h5 class="card-title text-center">
                                      <strong>{{"offers.request.routers" | translate }}</strong>
                                    </h5>
                                    <ul class="list-group list-group-flush">
                                      <li class="list-group-item p-0 border-bottom-0">
                                        <app-form-group-number-input
                                          [(model)]="eventOffer.hardwareRouter"
                                          (modelChange)="calculateEventOfferPrice()">
                                        </app-form-group-number-input>
                                      </li>
                                    </ul>
                                    <div class="card-body p-0">
                                      <div class="media">
                                        <div class="media-body text-muted">{{"offers.request.routerDesc" | translate}}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                          </div>
                        </div>
                        <ng-container *ngIf="!eventOffer.fullPackageRenting">
                          <div class="offset-lg-2 col-lg-8 col-md-12 text-center">
                            <h5></h5>
                            <h5 class="row mt-3">
                              {{ "offers.request.ownMaterialMsg" | translate }}
                            </h5>
                          </div>
                        </ng-container>
                        <div class="row mt-3" [hidden]="eventOffer.fullPackageRenting">
                          <div class="col">
                            <div class="card m-0">
                              <h5 class="card-title text-center">
                                <strong>{{"offers.request.phones" | translate}}</strong>
                              </h5>
                              <ul class="list-group list-group-flush">
                                <li class="list-group-item p-0 border-bottom-0">
                                  <app-form-group-number-input
                                    [(model)]="eventOffer.hardwarePhone"
                                    (modelChange)="calculateEventOfferPrice()"
                                    (negativeModelChange)="onHardwareQuantityChange('waiter', true)"
                                    (positiveModelChange)="onHardwareQuantityChange('waiter', false)">
                                  </app-form-group-number-input>
                                </li>
                              </ul>
                              <div class="card-body p-0">
                                <div class="media">
                                  <div class="media-body text-muted">{{"offers.request.smartphoneDesc" | translate }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div class="card m-0">
                              <h5 class="card-title text-center">
                                <strong>{{"offers.request.tablets" | translate}}</strong>
                              </h5>
                              <ul class="list-group list-group-flush">
                                <li class="list-group-item p-0 border-bottom-0">
                                  <app-form-group-number-input
                                    [(model)]="eventOffer.hardwareTablet"
                                    (modelChange)="calculateEventOfferPrice()">
                                  </app-form-group-number-input>
                                </li>
                              </ul>
                              <div class="card-body p-0">
                                <div class="media">
                                  <div class="media-body text-muted">{{ "offers.request.tabletsDesc" | translate }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div class="card m-0">
                              <h5 class="card-title text-center">
                                <strong>{{"offers.request.printers" | translate }}</strong>
                              </h5>
                              <ul class="list-group list-group-flush">
                                <li class="list-group-item p-0 border-bottom-0">
                                  <app-form-group-number-input
                                    [(model)]="eventOffer.hardwarePrinter"
                                    (modelChange)="calculateEventOfferPrice()"
                                    (negativeModelChange)="onHardwareQuantityChange('printer',true)"
                                    (positiveModelChange)="onHardwareQuantityChange('printer',false)">
                                  </app-form-group-number-input>
                                </li>
                              </ul>
                              <div class="card-body p-0">
                                <div class="media">
                                  <div class="media-body text-muted">{{ "offers.request.printerDesc" | translate }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col" *ngIf="chosenCountry?.id === 1">
                            <div class="card m-0">
                              <h5 class="card-title text-center">
                                <strong>{{"offers.request.fiscalPrinter" | translate }}</strong>
                              </h5>
                              <ul class="list-group list-group-flush">
                                <li class="list-group-item p-0 border-bottom-0">
                                  <app-form-group-number-input
                                    [(model)]="eventOffer.hardwareFiscalPrinter"
                                    (modelChange)="calculateEventOfferPrice()"
                                    (negativeModelChange)="onHardwareQuantityChange('fiscalPrinter', true)"
                                    (positiveModelChange)="onHardwareQuantityChange('fiscalPrinter', false)">
                                  </app-form-group-number-input>
                                </li>
                              </ul>
                              <div class="card-body p-0">
                                <div class="media">
                                  <div class="media-body text-muted">{{ "offers.request.fiscalPrinterDesc" | translate }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <ng-container *ngIf="!eventOffer.fullPackageRenting">
                            <div class="col">
                              <div class="card m-0">
                                <h5 class="card-title text-center">
                                  <strong>{{"offers.request.routers" | translate}}</strong>
                                </h5>
                                <ul class="list-group list-group-flush">
                                  <li class="list-group-item p-0 border-bottom-0">
                                    <app-form-group-number-input
                                      [(model)]="eventOffer.hardwareRouter"
                                      (modelChange)="calculateEventOfferPrice()">
                                    </app-form-group-number-input>
                                  </li>
                                </ul>
                                <div class="card-body p-0">
                                  <div class="media">
                                    <div class="media-body text-muted">{{"offers.request.routerDesc" | translate }}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                        <div class="row mt-3" *ngIf="deviceLicensingError.length > 0">
                          <div class="col">
                            <strong class="text-danger">{{deviceLicensingError }}</strong>
                          </div>
                        </div>
                        <div class="mt-5" *ngIf="showCableSection">
                          <div class="row">
                            <div class="col">
                              <p class="text-muted">
                                {{"offers.request.chooseCablesMsg1" | translate }}
                                <strong>{{ eventOffer.hardwarePrinter }}</strong>
                                {{"offers.request.chooseCablesMsg2" | translate }}
                              </p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg col-md-12">
                              <div class="row">
                                <div class="col">
                                  <div class="card m-0">
                                    <h5 class="card-title text-center">
                                      <strong>{{"offers.request.cable-1m" | translate }}</strong>
                                    </h5>
                                    <ul class="list-group list-group-flush">
                                      <li class="list-group-item p-0 border-bottom-0">
                                        <app-form-group-number-input [(model)]="cableProductsAmount.one">
                                        </app-form-group-number-input>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="card m-0">
                                    <h5 class="card-title text-center">
                                      <strong>{{"offers.request.cable-5m" | translate }}</strong>
                                    </h5>
                                    <ul class="list-group list-group-flush">
                                      <li class="list-group-item p-0 border-bottom-0">
                                        <app-form-group-number-input [(model)]="cableProductsAmount.five">
                                        </app-form-group-number-input>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="card m-0">
                                    <h5 class="card-title text-center">
                                      <strong>{{"offers.request.cable-10m" | translate}}</strong>
                                    </h5>
                                    <ul class="list-group list-group-flush">
                                      <li class="list-group-item p-0 border-bottom-0">
                                        <app-form-group-number-input [(model)]="cableProductsAmount.ten">
                                        </app-form-group-number-input>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col">
                                  <div class="card m-0">
                                    <h5 class="card-title text-center">
                                      <strong>{{"offers.request.cable-20m" | translate}}</strong>
                                    </h5>
                                    <ul class="list-group list-group-flush">
                                      <li class="list-group-item p-0 border-bottom-0">
                                        <app-form-group-number-input [(model)]="cableProductsAmount.twenty">
                                        </app-form-group-number-input>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="card m-0">
                                    <h5 class="card-title text-center">
                                      <strong>{{"offers.request.cable-30m" | translate}}</strong>
                                    </h5>
                                    <ul class="list-group list-group-flush">
                                      <li class="list-group-item p-0 border-bottom-0">
                                        <app-form-group-number-input [(model)]="cableProductsAmount.thirty">
                                        </app-form-group-number-input>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="card m-0">
                                    <h5 class="card-title text-center">
                                      <strong>{{"offers.request.cable-50m" | translate}}</strong>
                                    </h5>
                                    <ul class="list-group list-group-flush">
                                      <li class="list-group-item p-0 border-bottom-0">
                                        <app-form-group-number-input [(model)]="cableProductsAmount.fifty">
                                        </app-form-group-number-input>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col">
                              <div class="card-body p-0">
                                <div class="media">
                                  <div class="media-body text-muted" style="padding-top: 80px">
                                    {{ "offers.request.cable-desc" | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row" *ngIf="missingCableError.length > 0">
                            <div class="col">
                              <strong class="text-danger">{{missingCableError}}</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="eventOffer.fullPackageRenting !== undefined">
                <div class="col">
                  <div class="content">
                    <div class="card border">
                      <div class="card-header">
                        <div class="row">
                          <div class="offset-lg-2 col-lg-8 col-md-12 text-center">
                            <h4 class="mt-0 mb-1">
                              <b>{{"offers.request.additionalCosts.mainTitle" | translate}}</b>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="card-body" *ngIf="additionalCostsContainerIsOpen && additionalCostsCustomArray.length > 0">
                        <div class="row mt-3">
                          <ng-container *ngFor="let additionalCost of additionalCostsCustomArray; let i = index">
                            <div class="col-12 col-md-3 p-2">
                              <!-- groupNumber-->
                              <div class="card m-0" *ngIf="additionalCost.typeField === 'groupNumber'">
                                <h5 class="card-title text-center"><strong>{{additionalCost.title}}</strong></h5>
                                <div class="card-price p-0" *ngIf="showAdditionalCostAmount(additionalCost.id)">
                                  <h5 class="text-center">{{additionalCost.priceLabel}} <span style="white-space:nowrap; font-weight: bold;">{{additionalCost.cost}} {{additionalCost.typeShow === 'showValue' ? '€': '%'}}</span></h5>
                                </div>
                                <ul class="list-group list-group-flush">
                                  <li class="list-group-item p-0 border-bottom-0">
                                    <app-form-group-number-input
                                      [offset]="additionalCost.offset"
                                      [(model)]="additionalCost.value"
                                      (modelChange)="onAdditionalCostNumberInputChange(additionalCost.id)">
                                    </app-form-group-number-input>
                                  </li>
                                </ul>
                                <div class="card-body p-0">
                                  <div class="media">
                                    <div class="media-body text-muted">
                                      <strong>{{additionalCost.text}}</strong>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- radio-->
                              <div class="card m-0" *ngIf="additionalCost.typeField === 'radio'">
                                <h5 class="card-title text-center"><strong>{{additionalCost.title}}</strong></h5>
                                <div class="card-price p-0" *ngIf="showAdditionalCostAmount(additionalCost.id)">
                                  <h5 class="text-center">
                                      {{additionalCost.priceLabel}}
                                    <span style="white-space:nowrap; font-weight: bold;">
                                      {{additionalCost.cost}} {{additionalCost.typeShow === 'showValue' ? '€': '%'}}
                                    </span>
                                  </h5>
                                </div>
                                <ul class="list-group list-group-flush">
                                  <li class="list-group-item p-0 border-bottom-0">
                                    <div class="form-group row" style="height: 49px">
                                      <div class="col text-center">
                                        <div class="form-check form-check-inline" style="padding-top: 25px">
                                          <input
                                            class="form-check-input"
                                            type="radio"
                                            [name]="additionalCost.name"
                                            [(ngModel)]="additionalCost.value"
                                            (change)="onAdditionalCostNumberInputChange(additionalCost.id)"
                                            id="additionalCostRadioTrue-{{i}}"
                                            [disabled]="(additionalCost.id === 11 && eventOffer.softwareMainDevice < 1)"
                                            [value]="1"/>
                                          <label
                                            class="form-check-label"
                                            [htmlFor]="'additionalCostRadioTrue-' + i">
                                            {{"offers.request.yes" | translate }}
                                          </label>
                                        </div>
                                      </div>
                                      <div class="col text-center">
                                        <div class="form-check form-check-inline" style="padding-top: 25px">
                                          <input
                                            class="form-check-input"
                                            type="radio"
                                            [name]="additionalCost.name"
                                            [(ngModel)]="additionalCost.value"
                                            (change)="onAdditionalCostNumberInputChange(additionalCost.id)"
                                            id="additionalCostRadioFalse-{{i}}"
                                            [disabled]="(additionalCost.id === 11 && eventOffer.softwareMainDevice < 1)"
                                            [value]="0"/>
                                          <label
                                            class="form-check-label"
                                            [htmlFor]="'additionalCostRadioFalse-' + i">
                                            {{"offers.request.no" | translate}}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                                <div class="card-body p-0">
                                  <div class="media">
                                    <div class="media-body text-muted">
                                      <strong style="white-space: pre-wrap;">{{additionalCost.text}}</strong>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- simpleText-->
                              <div class="card m-0" *ngIf="additionalCost.typeField === 'simpleText'">
                                <h5 class="card-title text-center"><strong>{{additionalCost.title}}</strong></h5>
                                <div class="card-price p-0">
                                  <h5 class="text-center">{{additionalCost.priceLabel}} <span style="white-space:nowrap; font-weight: bold;">{{additionalCost.cost}} {{additionalCost.typeShow === 'showValue' ? '€': '%'}}</span></h5>
                                </div>
                                <div class="card-body p-0">
                                  <div class="media">
                                    <div class="media-body text-muted">
                                      <strong>{{additionalCost.text}}</strong>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 hr-space d-none d-md-block" *ngIf="additionalCost.endRow"><hr/></div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="margin: 15px 0 30px 0" *ngIf="eventOffer.discountedPrice > 0">
                <div class="col-lg-4"></div>
                <div class="col-lg-4 col-md-12 text-center">
                  <p class="lead mt-0 h2">
                    <strong>{{ "event.request.totalPrice" | translate }}:</strong>
                    {{ eventOffer.discountedPrice | currency : "€ " }}
                    + {{ "event.request.vat" | translate }}
                  </p>
                  <button class="btn btn-primary btn-lg btn-wd" type="button" (click)="submitEventOfferRequest()">
                    {{ "offers.request.submitOfferButton" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #missingCompanyModal let-missingCompanyModal>
  <div class="modal-header">
    <h4 class="modal-title" style="margin: 0 auto">{{ "event.request.missingCompanyModalTitle" | translate }}</h4>
  </div>
  <div class="modal-body">
    <p class="text-center">{{ "event.request.missingCompanyModalContent" | translate }}</p>
  </div>
  <div class="modal-footer">
    <a class="btn btn-outline-dark btn-block" href="/account">{{"event.request.missingCompanyButton" | translate}}</a>
  </div>
</ng-template>

<ng-template #missingRoutersWarningModal let-missingRoutersWarningModal>
  <div class="modal-header">
    <h4 class="modal-title" style="margin: 0 auto">{{ "offers.request.missingRouterWarningModalTitle" | translate }}</h4>
  </div>
  <div class="modal-body">
    <p class="text-center">{{ "offers.request.missingRouterWarningModalContent" | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col">
        <button type="button" class="btn btn-outline-dark" (click)="closeMissingRoutesWarningModal(false)">
          {{ "offers.request.missingRouterWarningStayButton" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #eventOfferCommentsModal let-eventOfferCommentsModal>
  <div class="modal-body">
    <div class="summary">
      <div class="title">
        <span class="h4">{{"offers.request.summary.noteTitle" | translate }}</span>
        {{ "offers.request.summary.noteSubTitle" | translate }}
      </div>
      <div class="content">
        <div class="row">
          <div class="element col-12">
            <div class="form-group">
              <textarea class="form-control" rows="4" [(ngModel)]="eventOffer.notes"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="summary">
      <div class="title">
        <span class="h4">{{ "offers.request.summary.title" | translate }}</span>
        {{ "offers.request.summary.subtitle" | translate }}
      </div>
      <div class="content">
        <div class="row">
          <div class="element col-12">
            <div class="label">{{ "offers.request.summary.eventName" | translate }}</div>
            <div class="data">{{ eventOffer.eventName }}</div>
          </div>
        </div>
        <div class="row">
          <div class="element col-6">
            <div class="label">
              {{ "offers.request.summary.eventDates" | translate }}
            </div>
            <div class="data">
              {{ this.summary.eventStartDate | date : "dd/MM/yyyy" }} -
              {{ this.summary.eventEndDate | date : "dd/MM/yyyy" }}
            </div>
          </div>
          <div class="element col-6">
            <div class="label">{{ "offers.request.summary.eventPlace" | translate }}</div>
            <div class="data">{{ this.summary.villageName }}</div>
          </div>
        </div>
        <div class="row">
          <div class="element col-6">
            <div class="label">{{ "offers.request.summary.hardwarePhone" | translate }}</div>
            <div class="data">{{ eventOffer.hardwarePhone }}</div>
          </div>
          <div class="element col-6">
            <div class="label">{{ "offers.request.summary.hardwareTablet" | translate }}</div>
            <div class="data">{{ eventOffer.hardwareTablet }}</div>
          </div>
        </div>
        <div class="row">
          <div class="element col-6">
            <div class="label">{{ "offers.request.summary.hardwarePrinter" | translate }}</div>
            <div class="data">{{ eventOffer.hardwarePrinter }}</div>
          </div>
          <div class="element col-6">
            <div class="label">{{ "offers.request.summary.hardwareFiscalPrinter" | translate }}</div>
            <div class="data">{{ eventOffer.hardwareFiscalPrinter }}</div>
          </div>
        </div>
        <div class="row">
          <div class="element col-6">
            <div class="label">{{ "offers.request.summary.hardwareRouter" | translate }}</div>
            <div class="data">{{ eventOffer.hardwareRouter }}</div>
          </div>
          <div class="element col-6">
            <div class="label">{{ "offers.request.summary.hardwareCable" | translate }}</div>
            <div class="data">
              {{
                cableProductsAmount.one +
                  cableProductsAmount.five +
                  cableProductsAmount.ten +
                  cableProductsAmount.fifteen +
                  cableProductsAmount.twenty +
                  cableProductsAmount.thirty +
                  cableProductsAmount.fifty
              }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="element col-6">
            <div class="label">{{ "offers.request.summary.softwareWaiter" | translate }}</div>
            <div class="data">{{ eventOffer.softwareWaiter }}</div>
          </div>
          <div class="element col-6">
            <div class="label">{{ "offers.request.summary.softwareCashRegister" | translate }}</div>
            <div class="data">{{ eventOffer.softwareCashRegister }}</div>
          </div>
        </div>
        <div class="row">
          <div class="element col-6">
            <div class="label">{{ "offers.request.summary.softwareMainDevice" | translate }}</div>
            <div class="data">{{ eventOffer.softwareMainDevice }}</div>
          </div>
        </div>
        <div class="row">
          <div class="element col-6">
            <div class="label">{{ "offers.request.summary.softwarePrinter" | translate }}</div>
            <div class="data">{{ eventOffer.softwarePrinter }}</div>
          </div>
          <div class="element col-6">
            <div class="label">{{ "offers.request.summary.softwareFiscalPrinter" | translate }}</div>
            <div class="data">{{ eventOffer.softwareFiscalPrinter }}</div>
          </div>
        </div>
        <div class="row">
          <div class="additionalcosttitle col-12 text-center"><h5><b><u>{{"offers.request.additionalCosts.mainTitle" | translate}}</u></b></h5></div>
          <ng-container *ngFor="let additionalCostsCustom of additionalCostsCustomArray">
            <div class="element col-12" *ngIf="!additionalCostsCustom.hiddenSummary">
              <div class="label">{{ additionalCostsCustom.titleSummary }}</div>
              <div class="data">{{ additionalCostsCustom.value === 0 ? additionalCostsCustom.valueSummaryZero : additionalCostsCustom.valueSummary }}</div>
            </div>
          </ng-container>
        </div>
        <div class="row">
          <div class="element col-12 lead mt-0 h4">
            <div class="label">{{ "event.request.totalPrice" | translate }}:</div>
            <div class="data" style="color: #000000">
              {{ eventOffer.discountedPrice | currency : "€ " }}+ {{ "event.request.vat" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="display: block">
    <div class="row">
      <div class="col">
        <button type="button" class="btn btn-outline-dark" (click)="eventOfferCommentsModal.close()">
          {{ "offers.request.missingRouterWarningStayButton" | translate }}
        </button>
      </div>
      <div class="col text-right">
        <button class="btn btn-outline-dark" type="button" (click)="submitEventOffer()">
          {{ "offers.request.submitOfferButton" | translate }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">{{ "offers.request.submitOfferButtonBelowText" | translate }}</div>
    </div>
  </div>
</ng-template>
