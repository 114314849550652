import { Event } from './Event';
import { TurnOverProduct } from './TurnOverProduct';
import { TurnOverOrderDate } from './TurnOverOrderDate';
import { TurnOverOption } from './TurnOverOption';
import { DeviceUser } from './DeviceUser';
import { DeviceUserType } from './DeviceUserType';

export class TurnOver {
    public id: number;
    public createdAt: string;
    public updatedAt: string;
    public device: DeviceUser;
    public deviceUserId: number;
    public deviceUserName: string;
    public deviceUserTypeId: number;
    public deviceUserTypeName: string;
    public priceListId: number;
    public priceListName: string;
    public event: Event;
    public turnOverProducts: TurnOverProduct[];
    public turnOverOptions: TurnOverOption[];
    public turnOverOrderDate: TurnOverOrderDate;

    static fromDTo(t): TurnOver {
        const turnover = new TurnOver();
        if (!t) {
            return null;
        }

        turnover.id = t.id;
        turnover.createdAt = t.createdAt;
        turnover.updatedAt = t.updatedAt;
        const device = new DeviceUser();
        const deviceUserType = new DeviceUserType();
        device.id = t.device.id;
        device.name = t.device.name;
        deviceUserType.id = t.device.deviceUserType.id;
        deviceUserType.name = t.device.deviceUserType.name;
        device.deviceUserType = deviceUserType;
        turnover.device = device;
        turnover.deviceUserId = t.deviceUserId;
        turnover.deviceUserName = t.deviceUserName;
        turnover.deviceUserTypeId = t.deviceUserTypeId;
        turnover.deviceUserTypeName = t.deviceUserTypeName;
        turnover.priceListId = t.priceListId;
        turnover.priceListName = t.priceListName;
        turnover.event = Event.fromDto(t.event);
        if (t.turnOverProducts) {
            turnover.turnOverProducts = t.turnOverProducts.map(product => TurnOverProduct.fromDto(product));
        }
        if (t.turnOverOptions) {
            turnover.turnOverOptions = t.turnOverOptions.map(option => TurnOverOption.fromDto(option));
        }
        turnover.turnOverOrderDate = TurnOverOrderDate.fromDto(t.turnOverOrderDate);
        return turnover;
    }
}
