import { Village } from './Village';
import { Company } from './Company';
import { Event } from './Event';
import { DeliveryAddress } from './DeliveryAddress';
import { PickupPoint } from './PickupPoint';
import { AdditionalCostsSaveOffer } from './AdditionalCost';

export class EventOffer {
    public id: number;
    public eventName: string;
    public startDate: string;
    public endDate: string;
    public pickUpDate: string;
    public returnDate: string;
    public createdAt: string;
    public softwareWaiter = 0;
    public softwareCashRegister = 0;
    public softwareMainDevice = 0;
    public softwarePrinter = 0;
    public softwareFiscalPrinter = 0;
    public hardwarePhone = 0;
    public hardwareTablet = 0;
    public hardwarePrinter = 0;
    public hardwareFiscalPrinter = 0;
    public hardwareRouter = 0;
    public hardwareUps = 0;
    public hardwareCable: string;
    public fullPrice: number;
    public discount: number;
    public discountedPrice: number;
    public insuranceCost: number;
    public vat: number;
    public discountedPriceWithVat: number;
    public offerId: string;
    public language: string;
    public zone: string;
    public status: number;
    public notes: string;
    public negativeNotes: string;
    public statusChangedAt: string;
    public versionLetter: string;
    public delivery: boolean;
    public fullPackageRenting: boolean;
    public village: Village;
    public company: Company;
    public event: Event;
    public deliveryAddress: DeliveryAddress;
    public pickupPoint: PickupPoint;
    public isAdvertise: boolean;
    public additionalCosts: AdditionalCostsSaveOffer[] = []; // only for save when create new offer

    static fromDto(e): EventOffer {
        const eventOffer = new EventOffer();
        if (!e) {
            return null;
        }
        eventOffer.id = e.id;
        eventOffer.eventName = e.eventName;
        eventOffer.startDate = e.startDate;
        eventOffer.endDate = e.endDate;
        eventOffer.createdAt = e.createdAt;
        eventOffer.pickUpDate = e.pickUpDate;
        eventOffer.returnDate = e.returnDate;
        eventOffer.softwareWaiter = e.softwareWaiter;
        eventOffer.softwareCashRegister = e.softwareCashRegister;
        eventOffer.softwareMainDevice = e.softwareMainDevice;
        eventOffer.softwarePrinter = e.softwarePrinter;
        eventOffer.softwareFiscalPrinter = e.softwareFiscalPrinter;
        eventOffer.hardwarePhone = e.hardwarePhone;
        eventOffer.hardwareTablet = e.hardwareTablet;
        eventOffer.hardwarePrinter = e.hardwarePrinter;
        eventOffer.hardwareFiscalPrinter = e.hardwareFiscalPrinter;
        eventOffer.hardwareRouter = e.hardwareRouter;
        eventOffer.hardwareUps = e.hardwareUps;
        eventOffer.hardwareCable = e.hardwareCable;
        eventOffer.fullPrice = e.fullPrice;
        eventOffer.discount = e.discount;
        eventOffer.discountedPrice = e.discountedPrice;
        eventOffer.vat = e.vat;
        eventOffer.discountedPriceWithVat = e.discountedPriceWithVat;
        eventOffer.offerId = e.offerId;
        eventOffer.language = e.language;
        eventOffer.zone = e.zone;
        eventOffer.status = e.status;
        eventOffer.notes = e.notes;
        eventOffer.negativeNotes = e.negativeNotes;
        eventOffer.statusChangedAt = e.statusChangedAt;
        eventOffer.versionLetter = e.versionLetter;
        eventOffer.delivery = e.delivery;
        eventOffer.fullPackageRenting = e.fullPackageRenting;
        eventOffer.isAdvertise = e.isAdvertise;
        eventOffer.village = Village.fromDto(e.village);
        eventOffer.company = Company.fromDto(e.company);
        eventOffer.event = Event.fromDto(e.event);
        eventOffer.deliveryAddress = DeliveryAddress.formDto(e.deliveryAddress);
        eventOffer.pickupPoint = PickupPoint.fromDto(e.pickupPoint);
        return eventOffer;
    }
}
