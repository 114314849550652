import { Component, OnInit, } from '@angular/core';
import { StatisticsService } from './statistics.service';
import { EventService } from '../../shared/event.service';
import * as moment from 'moment';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {
  public hasNewTurnoverData = true;
  public event: any;

  constructor(private _statisticsKendoService: StatisticsService, private _eventService: EventService) {
  }

  public ngOnInit(): void {
    this._eventService.currentEventObject.subscribe({
      next: (eventResult) => {
        this.event = eventResult;
        if (!this.event?.id) {
          return;
        }
        this._statisticsKendoService.getTurnoverData(
          this.event.id,
          this.event.priceLists[0].id,
          moment(new Date(this.event.startDate)).format('DD-MM-YYYY'),
          moment(new Date(this.event.endDate)).format('DD-MM-YYYY'),
        ).subscribe({
          next: (statisticTurnoverData) => {
            if (!statisticTurnoverData.isNew) { // prevents re-building the grid if the response is the same
              this.hasNewTurnoverData = false;
            }
          },
          error: () => console.log('Unable to get turnover data.')
        });
    },
      error: () => console.log('Unable to get event data')
    });
  }

}
