import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Printer } from '../../shared/models/Printer';
import { PrintersService } from '../shared/printers.service';
import { Option } from '../../shared/form-group-select/form-group-select.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { NotificationService } from '@progress/kendo-angular-notification';

@Component({
  selector: 'app-rent-printer-form',
  templateUrl: './rent-printers-form.component.html',
  styleUrls: ['./rent-printers-form.component.css']
})
export class RentPrintersFormComponent implements OnInit {
  @ViewChild('notificationContainer', {read: ViewContainerRef})
  public notificationContainer: ViewContainerRef;

  public form: FormGroup;

  @Input()
  public typeOptions: Option[];

  @Input()
  public currentClientPrinters: Printer[];

  public printers: Printer[];

  forms: FormGroup[] = [];

  nrPrinters = 1;

  steps: any[];

  currentStep = 0;

  public errorMsg?: string;

  constructor(
    private formBuilder: FormBuilder,
    private printersService: PrintersService,
    private translateService: TranslateService,
    private dialogRef: DialogRef,
    private notificationService: NotificationService
  ) {
    this.generateForms();
    this.generateSteps();
    this.generatePrinters();
  }

  ngOnInit(): void {
    this.forms = Array.from({length: this.nrPrinters}, () => {
      return this.formBuilder.group({
        search: ['7473F1', [Validators.required, Validators.minLength(1)]]
      });
    });
  }

  public submit() {
      if (this.forms[this.currentStep].valid) {
        this.errorMsg = null;
        const rentingCode = this.forms[this.currentStep].get('search').value;
        this.printersService.searchByRentingCode(rentingCode).subscribe({
          next: (res) => {
            if (res) {
              if (this.currentClientPrinters.findIndex((p) => p.id === res.id) !== -1) {
                this.errorMsg = this.translateService.instant('printers.errorMsg.rentingCode.ownPrinter');
                return;
              }
              this.printers[this.currentStep] = res;
            } else {
              this.errorMsg = this.translateService.instant('printers.errorMsg.rentingCode.notFound');
            }
          },
          error: (err) => {
            if (err.error.result === 'not_found') {
              this.errorMsg = this.translateService.instant('printers.errorMsg.rentingCode.notFound');
            } else {
              this.errorMsg = this.translateService.instant('printers.errorMsg.searchError');
            }
          },
        });
      } else {
        this.errorMsg = this.translateService.instant('printers.errorMsg.rentingCode.invalid');
      }
  }

  onNrPrinterChange($event: Event) {
    const target = $event.target as HTMLInputElement;
    if (!target.value || target.value && isNaN(Number(target.value))) {
      return;
    }

    let currentNr = Number(target.value);

    // Force max number of printers: 4
    if (currentNr > 4) {
      currentNr = 4;
      alert(this.translateService.instant('printers.message.maxPrinters'));
    }

    // When printer number is increased
    if (this.nrPrinters < currentNr) {
      const newSteps = Array.from({length: currentNr - this.nrPrinters}, (_, i) => ({
        label: `${i + 1 + this.nrPrinters}`
      }));

      const newForms = Array.from({length: currentNr - this.nrPrinters}, () => {
        return this.formBuilder.group({
          search: ['7473F1', [Validators.required, Validators.minLength(1)]]
        });
      });

      const newPrinters = Array.from({length: currentNr - this.nrPrinters}, () => {
        const newPrinter = new Printer();
        newPrinter.id = 0;
        newPrinter.name = '';
        newPrinter.status = 0;
        newPrinter.rentingCode = '';
        newPrinter.events = null;
        newPrinter.printerType = null;
        newPrinter.owner = null;
        return newPrinter;
      });

      for (let i = 0; i < newSteps.length; i++) {

        this.steps.push(newSteps[i]);
        this.forms.push(newForms[i]);
        this.printers.push(newPrinters[i]);
      }

    }

    // When printer number is decreased
    if (this.nrPrinters > currentNr) {
      for (let i = 0; i < this.nrPrinters - currentNr; i++) {
        this.steps.splice(this.steps.length - 1);
        this.forms.splice(this.forms.length - 1);
        this.printers.splice(this.printers.length - 1);
      }
    }

    this.nrPrinters = currentNr;
    this.currentStep = 0;
  }

  private generateSteps() {
    this.steps = Array.from({length: this.nrPrinters}, (_, i) => ({
      label: `${i + 1}`
    }));
  }

  private generateForms() {
    this.forms = Array.from({length: this.nrPrinters}, () => {
      return this.formBuilder.group({
        search: ['7473F1', [Validators.required, Validators.minLength(1)]]
      });
    });
  }

  private generatePrinters() {
    this.printers = Array.from({length: this.nrPrinters}, () => {
      const newPrinter = new Printer();
      newPrinter.id = 0;
      newPrinter.name = '';
      newPrinter.status = 0;
      newPrinter.rentingCode = '';
      newPrinter.events = null;
      newPrinter.printerType = null;
      newPrinter.owner = null;
      return newPrinter;
    });
  }

  onNextClick() {
    if (this.currentStep < this.nrPrinters - 1) {
      this.currentStep += 1;

    }
  }

  onBackClick() {
    if (this.currentStep > 0) {
      this.currentStep -= 1;
    }
  }


  public addPrinters() {
    for (let i = 0; i < this.forms.length; i++) {
      if (this.printers[i].owner == null ||
          this.printers[i].name == null ||
          this.printers[i].printerType == null ||
          this.printers[i].identifier == null
      ) {
        return alert(this.translateService.instant('printers.message.addError'));
      }
    }

    this.dialogRef.close();
    this.printersService.insertRented(this.printers.map((p) => p.rentingCode)).subscribe({
      next: () => {
        this.showNotification(this.translateService.instant('printers.message.rented'), 'success');
        setTimeout(() => window.location.reload(), 1000);
      },
      error: () => {
        this.showNotification(this.translateService.instant('printers.message.addError'), 'error');
      },
    });
  }

  private showNotification(message: string, type: 'success' | 'error' | 'info' | 'warning') {
    this.notificationService.show({
      content: message,
      appendTo: this.notificationContainer,
      position: {horizontal: 'center', vertical: 'bottom'},
      type: {style: type, icon: false},
    });
  }

}
