import { Component, Input, ViewChild } from '@angular/core';
import { CellClickEvent, GridComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-order-products',
  templateUrl: './order-products.component.html',
  styleUrls: ['./order-products.component.css']
})
export class OrderProductsComponent {
  @Input() orderProducts: any;
  @ViewChild(GridComponent) grid: GridComponent;

  public expandedDetailKeys: number[] = [];
  public expandDetailsBy = (dataItem: any) => dataItem.id;

  public onCellClick(event: CellClickEvent): void {
    const findKeyState = this.expandedDetailKeys.findIndex((i) => i === event.dataItem.id);
    if (findKeyState === -1) {
      this.expandedDetailKeys.push(event.dataItem.id);
    } else {
      this.expandedDetailKeys.splice(findKeyState, 1);
    }
    this.expandedDetailKeys = [...this.expandedDetailKeys];
  }
  public showOptionDetailsCondition(dataItem: any) {
    return dataItem.orderOption.length > 0;
  }
}
