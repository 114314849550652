import { Component, OnInit } from '@angular/core';
import { EventService } from '../../shared/event.service';
import { Event } from 'src/app/shared/models/Event';
import { RequestedDevice } from 'src/app/shared/models/RequestedDevice';
import { RequestedCable } from 'src/app/shared/models/RequestedCable';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-general-details',
  templateUrl: './general-details.component.html',
  styleUrls: ['./general-details.component.css']
})
export class GeneralDetailsComponent implements OnInit {

  public event: Event = new Event();
  public arrayDevices: RequestedDevice[];

  public requestedPhoneHardware = 0;
  public requestedPhoneSoftware = 0;

  public requestedTabletHardware = 0;
  public requestedTabletSoftware = 0;

  public requestedPrinterHardware = 0;
  public requestedPrinterSoftware = 0;

  public requestedFiscalPrinterHardware = 0;
  public requestedFiscalPrinterSoftware = 0;

  public additionalCostsArray: {name: string, title: string, type: number, quantity: number} [] = [];

  constructor(private _eventService: EventService, private _translateService: TranslateService) { }

  ngOnInit() {
    this._eventService.updateCurrentActiveSubPage('general');
    this._eventService.currentEventObject.subscribe(result => {
      if (result) {
        this.event = result;
        // console.log('event:', this.event);
        // console.log('requestedDevices:', this.event.requestedDevices);
        // console.log('requestedSoftware:', this.event.requestedSoftwareTypes);
        // console.log('requestedSoftware:', this.event.requestedSoftwareTypes);
        // console.log('event.printers.length:', this.event.printers.length);
        // console.log('getTotalQuantity:', this.getTotalQuantity(this.event.requestedPrinterTypes));
        this.event.requestedDevices.forEach(reqHardware => {
          switch (reqHardware.deviceTypes.id) {
            case 1:
              this.requestedPhoneHardware += reqHardware.quantity;
              break;
            case 2:
              this.requestedTabletHardware += reqHardware.quantity;
              break;
            case 3:
              this.requestedTabletHardware += reqHardware.quantity;
              break;
          }
        });

        this.event.requestedPrinterTypes.forEach(reqHardware => {
          if (reqHardware.printerType.isFiscalPrinter) {
            this.requestedFiscalPrinterHardware += reqHardware.quantity;
          } else {
            this.requestedPrinterHardware += reqHardware.quantity;
          }
        });

        this.event.requestedSoftwareTypes.forEach(reqSoftware => {
          switch (reqSoftware.softwareType.id) {
            case 1:
              this.requestedPhoneSoftware += reqSoftware.quantity;
              break;
            case 2:
              this.requestedTabletSoftware += reqSoftware.quantity;
              break;
            case 3:
              this.requestedTabletSoftware += reqSoftware.quantity;
              break;
            case 4:
              this.requestedPrinterSoftware += reqSoftware.quantity;
              break;
            case 5:
              this.requestedFiscalPrinterSoftware += reqSoftware.quantity;
              break;
          }
        });

        this._eventService.getAdditionalCostsAssigned(this.event.id.toString()).subscribe(rs => {
          rs.forEach(rec => {
            const el = {
              name: rec.name,
              title: this._translateService.instant('offers.request.additionalCosts.' + rec.name + '.title'),
              type: rec.type,
              quantity: rec.quantity
            };
            this.additionalCostsArray.push(el);
          });
        });

      }
    });

  }


  getGroupByDeviceUsers(deviceTypeQuantity: number, deviceTypeId: number) {
    let softwareQuantity = 0;
    this.event.requestedSoftwareTypes.forEach(reqSoftware => {
      if (reqSoftware.softwareType.id === 1 && deviceTypeId === 1) {
        softwareQuantity += reqSoftware.quantity;
      } else {
        if ((reqSoftware.softwareType.id === 2 || reqSoftware.softwareType.id === 3) && deviceTypeId === 2) {
          softwareQuantity += reqSoftware.quantity;
        }
      }
    });
    return softwareQuantity - deviceTypeQuantity;
  }

  getTotalQuantity(requestedList: any[]) {
    return requestedList.reduce((acc, curr) => acc + curr.quantity, 0);
  }

  getGroupByCables(cableList: RequestedCable[]): RequestedCable[] {
    const cables: RequestedCable[] = [];
    cableList.forEach((c) => {
      const searchCable = cables.find(cb => cb.cables.cableType.length === c.cables.cableType.length);
      if (searchCable == null) {
        cables.push(c);
      } else {
        searchCable.quantity += c.quantity;
      }
    });
    return cables;
  }

}
