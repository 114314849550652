import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CellClickEvent, GridComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-order-categories',
  templateUrl: './order-categories.component.html',
  styleUrls: ['./order-categories.component.css']
})
export class OrderCategoriesComponent implements OnInit {
  @ViewChild(GridComponent) grid: GridComponent;
  @Input() orderCategories: any;

  public expandedDetailKeys: number[] = [];
  public expandDetailsBy = (dataItem: any) => dataItem.id;

  ngOnInit() {
    this.expandedDetailKeys.push(...this.orderCategories.map((i: any) => i.id));
  }


  public onCellClick(event: CellClickEvent): void {
    const findKeyState = this.expandedDetailKeys.findIndex((i) => i === event.dataItem.id);
    if (findKeyState === -1) {
      this.expandedDetailKeys.push(event.dataItem.id);
    } else {
      this.expandedDetailKeys.splice(findKeyState, 1);
    }
    this.expandedDetailKeys = [...this.expandedDetailKeys];
  }
}
