<div class="row">
  <div class="col">
    <div class="jumbotron" style="padding-top: 0px; padding-bottom: 0px;">
      <h1 class="display-4">{{'assign-category-printer.title' | translate}}</h1>
      <p class="lead">{{'assign-category-printer.description' | translate}}</p>
      <hr class="my-4">
    </div>
  </div>
</div>
<div [hidden]="!loadingContent">
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
<div [hidden]="loadingContent">
  <div class="card">
    <div class="card-body">
      <div class="tab">
        <button class="tablinks" (click)="changeTab($event, 0)">{{'assign-category-printer.tab1' | translate}}</button>
        <button class="tablinks" (click)="changeTab($event, 1)">{{'assign-category-printer.tab2' | translate}}</button>
        <button class="tablinks" (click)="changeTab($event, 2)">{{'assign-category-printer.tab3' | translate}}</button>
      </div>
      <div class="p-3" style="border: 1px solid #dee2e6;">
        <div *ngIf="currentTab === 0">
          <div class="row" *ngIf="event.printers.length > 0">
            <div class="col-12 col-sm-6" *ngFor="let deviceUsersPriceListArray of tableDeviceUsersPriceList">
              <table class="table" style="display: block; overflow: auto;" *ngFor="let element of deviceUsersPriceListArray">
                <tbody *ngIf="!element.deviceUser.paidWithoutPrint">
                  <tr>
                    <td [attr.colspan]="(event.printers.length + 1)" class="border-0">
                      <h6 class="m-3">{{element.deviceUser.name}}</h6>
                    </td>
                  </tr>
                    <ng-container *ngFor="let category of element.printerCategory">
                      <ng-container *ngIf="element.deviceUser.deviceUserType.id !== 3 || (element.deviceUser.deviceUserType.id === 3 && category.productCategory.id === 99999)"> <!-- this if exclude berichts-->
                        <tr>
                          <td><div>{{category.productCategory.name}}</div></td>
                          <td>
                            <div class="row">
                              <div *ngFor="let printer of event.printers" style="float:left; padding:3px 5px; margin:3px;">
                                <app-custom-switch-printer [printer]="printer" [deviceUsersPriceList]="element"
                                  [printerCategory]="category" (onSwitchChange)="onCheckBoxChange($event)" [eventFinished]="false">
                                </app-custom-switch-printer>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>
                </tbody>
                <tbody *ngIf="element.deviceUser.paidWithoutPrint">
                  <tr>
                    <td [attr.colspan]="(event.printers.length + 1)" class="border-0">
                      <h6 class="m-3">{{element.deviceUser.name}} ({{'assign-category-printer.noPrint' | translate}})</h6>
                    </td>
                  </tr>
                    <ng-container *ngFor="let category of element.printerCategory">
                      <ng-container *ngIf="element.deviceUser.deviceUserType.id !== 3 || (element.deviceUser.deviceUserType.id === 3 && !category.productCategory.id)"> <!-- this if exclude berichts-->
                        <tr>
                          <td><div>{{category.productCategory.name}}</div></td>
                          <td>
                            <div class="row">
                              <div *ngFor="let printer of event.printers" style="float:left; padding:3px 5px; margin:3px;">
                                <app-custom-switch-printer [printer]="printer" [deviceUsersPriceList]="element" [disabled]="true"
                                  [printerCategory]="category" (onSwitchChange)="onCheckBoxChange($event)" [eventFinished]="true">
                                </app-custom-switch-printer>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="currentTab === 1">
          <div class="row">
            <div class="col-12 col-sm-6" *ngFor="let deviceUsersByTypePriceListArray of tableDeviceUsersTypePriceList">
              <table class="table" style="display: block; overflow: auto;" *ngFor="let element of deviceUsersByTypePriceListArray">
                <tbody>
                  <div *ngIf="element.deviceUser.deviceUserType.id !== 3">
                    <tr>
                      <td [attr.colspan]="(event.printers.length + 1)" class="border-0">
                        <h6 class="m-3">{{element.deviceUser.deviceUserType.name}} ({{element.deviceUser.priceList.name}})</h6>
                      </td>
                    </tr>
                    <tr *ngFor="let category of element.printerCategory">
                      <ng-container *ngIf="category.productCategory.id">
                        <td><div>{{category.productCategory.name}}</div></td>
                        <td>
                          <div class="row">
                            <div *ngFor="let printer of event.printers" style="float:left; padding:3px 5px; margin:3px">
                              <app-custom-switch-printer [printer]="printer" [deviceUsersPriceList]="element"
                                [printerCategory]="category" (onSwitchChange)="onDeviceTypeCheckBoxChange($event)">
                              </app-custom-switch-printer>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                  </div>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="currentTab === 2">
          <div class="row">
            <table class="table">
              <tbody>
                <tr>
                  <td [attr.colspan]="(event.printers.length + 1)" class="border-0">
                    <h6 class="m-3">{{'assign-category-printer.report' | translate}}</h6>
                  </td>
                </tr>
                <tr *ngFor="let category of berichtDeviceUsersByTypePriceList.printerCategory">
                  <td><div>{{category.productCategory.name}}</div></td>
                  <td>
                    <div class="row">
                      <div *ngFor="let printer of event.printers" style="float:left; padding:3px 5px; margin:3px;">
                        <app-custom-switch-printer [printer]="printer
                          "[deviceUsersPriceList]="berichtDeviceUsersByTypePriceList" [printerCategory]="category"
                          (onSwitchChange)="onDeviceTypeCheckBoxChange($event)">
                        </app-custom-switch-printer>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="event.printers.length > 0">
        <div class="col">
          <div class="text-right mt-3">
            <!-- <button type="button" [hidden]="false" class="btn btn-danger btn-sm mr-2" (click)="onCancelButtonClick()"><i
                class="fa fa-times"></i>Cancel</button> -->
            <button type="button" [disabled]="false" class="btn btn-primary btn-sm" (click)="saveSettings()"><i
                class="fa fa-save"></i>
              {{'assign-category-printer.save' | translate}}</button>
          </div>
        </div>
      </div>
      <div style="text-align: center; font-weight: bold; font-size: medium;" *ngIf="event.printers.length < 1">
          {{'assign-category-printer.noPrinters' | translate}}
      </div>
    </div>
  </div>
</div>

<ng-template #successModal let-successModal>
  <div class="modal-body">
    <p class="text-center">
      {{'conclutionModal.description' | translate}}
    </p>
    <p class="text-center">
      {{'conclutionModal.description2' | translate}}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark"
      (click)="successModal.close()">{{'conclutionModal.closeBtn' | translate}}</button>
  </div>
</ng-template>
